<script setup lang="ts">
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { currentAuthenticatedUser } from '~/services/get-user';
import { getBannerMessage, isPersistent } from '~/services';

Amplify.configure(amplifyconfig)
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Crowdstake` : 'Crowdstake';
  },
  meta: [
    { name: 'description', content: 'Crowdstake site.' }
  ]
})
const isLoading = ref(true)

onBeforeMount(() => {
  const route = useRoute()
  currentAuthenticatedUser(route, isLoading);
})

</script>
<template>
  <!-- eslint-disable-next-line -->
  <div v-if="getBannerMessage()" id="banner" :class="{'persistent': isPersistent()}" v-html="getBannerMessage()" />
  <loading v-if="isLoading" />
  <NuxtLayout v-else :class="{'top-distance': isPersistent()}">
    <NuxtPage />
  </NuxtLayout>
</template>
