import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { OrganizationTransaction } from '~/models-ui';

const query = gql`

    query OrganizationWithdrawals {
        searchOrganizationTransactions(transactionType: Withdraw) {
            updatedAt
            createdAt
            chainTokenId
            amount
            address
            fiatAmount
            accountNumberLast4
            fiatCurrency
            error
            id
            status
            organizationName
        }
    }`
// eslint-disable-next-line import/no-mutable-exports
export let OrganizationWithdrawalsLoading: Ref<boolean> = ref(false)
export const getOrganizationWithdrawals = (): ComputedRef<Array<OrganizationTransaction>> => {
  OrganizationWithdrawalsLoading.value = true;
  const runQuery = (): any => provideApolloClient(getClient())(() => useQuery(query))
  const { result, loading } = runQuery()
  OrganizationWithdrawalsLoading = loading
  return computed(() => result.value?.searchOrganizationTransactions
    // eslint-disable-next-line no-unsafe-optional-chaining
    ? [...result.value?.searchOrganizationTransactions].sort(function (a: OrganizationTransaction, b: OrganizationTransaction) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      })
    : [])
}
