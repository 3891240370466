import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { Organization } from '~/models-ui';

const query = gql`
    query getAnonymousDonations($id: ID!) {
        getOrganization(id: $id) {
          enableAnonymousDonations
          stripeCapabilityState
          enableCardPayments
          enableCryptoPayments
          enableCryptoStaking
        }
    }`

export const getAnonymousDonations = (data: any) : ComputedRef<Organization> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getOrganization ?? {});
}
