import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';

const mutation = gql`
    mutation CreateOrganizationTransaction($wallet: String, $amount: Float!, $id: String!, $accountNumberLast4: String, $accountHolder: String, $chainTokenId: String, $transactionType: OrganizationTransactionType!, $fiatCurrency: FiatCurrency ) {
        createTransactionForOrganization(
            address: $wallet,
            transactionType: $transactionType,
            fiatCurrency: $fiatCurrency,
            amount: $amount,
            accountHolder: $accountHolder,
            accountNumberLast4: $accountNumberLast4,
            organizationId: $id,
            chainTokenId: $chainTokenId)
    }`

export const CreateOrganizationTransaction = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
