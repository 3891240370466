import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';
const mutation = gql`
    mutation CreateOrganization($address: String, $city: String, $phoneNumber: String, $organizationType: OrganizationType, $industryCode: String, $subdivisionCode: String, $countryCode: String, $ein: String, $fee: Float, $legalName: String!, $code: String!, $zip: String ) {
        createOrganization(input: {
            address: $address,
            city: $city,
            countryCode: $countryCode,
            industryCode: $industryCode,
            ein: $ein,
            fee: $fee,
            code: $code,
            legalName: $legalName,
            phoneNumber: $phoneNumber,
            organizationType: $organizationType,
            subdivisionCode: $subdivisionCode,
            zip: $zip,
        }) {
            id
        }
    }`

export const Create = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
