import { default as business_45signupoc8l5CGkJtMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/business-signup.vue?macro=true";
import { default as details74ieIBfT5gMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/details.vue?macro=true";
import { default as donationsdcoM2kzfd8Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/donations.vue?macro=true";
import { default as indexN0uRamvaLcMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/index.vue?macro=true";
import { default as stripeuihcDSqIH2Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/stripe.vue?macro=true";
import { default as transactions4a8rq3ZJyNMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/transactions.vue?macro=true";
import { default as financeLCzRsBiKlnMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance.vue?macro=true";
import { default as indexc00i9gFnrbMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/index.vue?macro=true";
import { default as settingsHbQ9NPObcaMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/settings.vue?macro=true";
import { default as indexXhl97xTCJ3Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/index.vue?macro=true";
import { default as mfaAJOuAhCYgGMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/mfa.vue?macro=true";
import { default as personal_45detailsX6xKSGsC8eMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/personal-details.vue?macro=true";
import { default as _91user_id_93cjsEzbaAYjMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id].vue?macro=true";
import { default as indexQSkugZ4YiDMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/index.vue?macro=true";
import { default as _91id_93Btx9JqbWQTMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/[id].vue?macro=true";
import { default as index5zeBFsFCrUMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/index.vue?macro=true";
import { default as listUYnVXfi95YMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients/list.vue?macro=true";
import { default as clientsCXBjhAXGGuMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/clients.vue?macro=true";
import { default as contributorsxXOBQmxm7vMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations/contributors.vue?macro=true";
import { default as index0tCBAn0bapMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations/index.vue?macro=true";
import { default as one_45timeOI3h4HRB6dMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations/one-time.vue?macro=true";
import { default as recurringFEidEQDCLHMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations/recurring.vue?macro=true";
import { default as stakinguXAtOlJWvbMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations/staking.vue?macro=true";
import { default as donationsYGga3Q1v43Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/donations.vue?macro=true";
import { default as indexvfvz0VYF4nMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/index.vue?macro=true";
import { default as indexNTxfqf6Z1PMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/index.vue?macro=true";
import { default as payoutsemYju2FC4MMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/payouts.vue?macro=true";
import { default as transactionss0nvidV98nMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/transactions.vue?macro=true";
import { default as pool_91id_935aQ4MP7UXJMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id].vue?macro=true";
import { default as companySg5VWxCDXzMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/company.vue?macro=true";
import { default as connectnuwTWbDgMKMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/connect.vue?macro=true";
import { default as indexDEob85c4TkMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/donations/index.vue?macro=true";
import { default as one_45timeBc3E4vXkzvMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/donations/one-time.vue?macro=true";
import { default as recurringPJyd86QkuOMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/donations/recurring.vue?macro=true";
import { default as stakingiOBOb22v29Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/donations/staking.vue?macro=true";
import { default as donationsMddbZdC4RxMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/donations.vue?macro=true";
import { default as financeYEFJ8t9Cw4Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/finance.vue?macro=true";
import { default as forgot_45passwordGrs4UcggEvMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/forgot-password.vue?macro=true";
import { default as homepMxn0IjoV2Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/home.vue?macro=true";
import { default as indexkVzAZJYabrMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/index.vue?macro=true";
import { default as newTbd9zLmwfVMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/new.vue?macro=true";
import { default as indexRgY74jRdy0Meta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/index.vue?macro=true";
import { default as mfaZkyhkC0tPIMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/mfa.vue?macro=true";
import { default as passwordkTqaLGCr8cMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/password.vue?macro=true";
import { default as personal_45detailslUjMgNoKyRMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/personal-details.vue?macro=true";
import { default as account_45detailsuJLdFRL0YiMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details.vue?macro=true";
import { default as bank_45detailsVRVUGzPgPQMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/bank-details.vue?macro=true";
import { default as indexw8RXEsfmNgMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/index.vue?macro=true";
import { default as organization_45detailsitLbgk46QpMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/organization-details.vue?macro=true";
import { default as widgetCIXknCUVZtMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/widget.vue?macro=true";
import { default as organization_45settingslZaF7QAwYhMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/organization-settings.vue?macro=true";
import { default as _91code_93Eppcc1bijnMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/payments/[code].vue?macro=true";
import { default as indexbxl16OmctDMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/payments/index.vue?macro=true";
import { default as settingsuYCC7F7BfxMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/settings.vue?macro=true";
import { default as signupGfC7GbyQliMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/signup.vue?macro=true";
import { default as user_45signupkmEvWhvbkjMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/user-signup.vue?macro=true";
import { default as _91id_9308X0Y3yp1tMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/[id].vue?macro=true";
import { default as indexAbyHVG4V8QMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/index.vue?macro=true";
import { default as listZdJDz94iIOMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/list.vue?macro=true";
import { default as withdrawalsWe1GuITdptMeta } from "/codebuild/output/src450006979/src/Web/Website/pages/withdrawals.vue?macro=true";
export default [
  {
    name: "business-signup___en",
    path: "/business-signup",
    meta: business_45signupoc8l5CGkJtMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/business-signup.vue").then(m => m.default || m)
  },
  {
    name: clientsCXBjhAXGGuMeta?.name,
    path: "/clients",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93Btx9JqbWQTMeta?.name,
    path: ":id()",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "clients-id-details___en",
    path: "details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: financeLCzRsBiKlnMeta?.name,
    path: "finance",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "clients-id-finance-donations___en",
    path: "donations",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/donations.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-finance___en",
    path: "",
    meta: indexN0uRamvaLcMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-finance-stripe___en",
    path: "stripe",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/stripe.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-finance-transactions___en",
    path: "transactions",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/finance/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clients-id___en",
    path: "",
    meta: indexc00i9gFnrbMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-settings___en",
    path: "settings",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: _91user_id_93cjsEzbaAYjMeta?.name,
    path: "users/:user_id()",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id].vue").then(m => m.default || m),
    children: [
  {
    name: "clients-id-users-user_id___en",
    path: "",
    meta: indexXhl97xTCJ3Meta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-users-user_id-mfa___en",
    path: "mfa",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/mfa.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-users-user_id-personal-details___en",
    path: "personal-details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/[user_id]/personal-details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clients-id-users___en",
    path: "users",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/[id]/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clients___en",
    path: "",
    meta: index5zeBFsFCrUMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-list___en",
    path: "list",
    meta: listUYnVXfi95YMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/clients/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: companySg5VWxCDXzMeta?.name,
    path: "/company",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: donationsYGga3Q1v43Meta?.name,
    path: "donations",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations.vue").then(m => m.default || m),
    children: [
  {
    name: "company-donations-contributors___en",
    path: "contributors",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations/contributors.vue").then(m => m.default || m)
  },
  {
    name: "company-donations___en",
    path: "",
    meta: index0tCBAn0bapMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations/index.vue").then(m => m.default || m)
  },
  {
    name: "company-donations-one-time___en",
    path: "one-time",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations/one-time.vue").then(m => m.default || m)
  },
  {
    name: "company-donations-recurring___en",
    path: "recurring",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations/recurring.vue").then(m => m.default || m)
  },
  {
    name: "company-donations-staking___en",
    path: "staking",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/donations/staking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "company___en",
    path: "",
    meta: indexvfvz0VYF4nMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: pool_91id_935aQ4MP7UXJMeta?.name,
    path: "pool:id()",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id].vue").then(m => m.default || m),
    children: [
  {
    name: "company-poolid___en",
    path: "",
    meta: indexNTxfqf6Z1PMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "company-poolid-payouts___en",
    path: "payouts",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/payouts.vue").then(m => m.default || m)
  },
  {
    name: "company-poolid-transactions___en",
    path: "transactions",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/company/pool[id]/transactions.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "connect___en",
    path: "/connect",
    meta: connectnuwTWbDgMKMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/connect.vue").then(m => m.default || m)
  },
  {
    name: donationsMddbZdC4RxMeta?.name,
    path: "/donations",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/donations.vue").then(m => m.default || m),
    children: [
  {
    name: "donations___en",
    path: "",
    meta: indexDEob85c4TkMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/donations/index.vue").then(m => m.default || m)
  },
  {
    name: "donations-one-time___en",
    path: "one-time",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/donations/one-time.vue").then(m => m.default || m)
  },
  {
    name: "donations-recurring___en",
    path: "recurring",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/donations/recurring.vue").then(m => m.default || m)
  },
  {
    name: "donations-staking___en",
    path: "staking",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/donations/staking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "finance___en",
    path: "/finance",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/finance.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45passwordGrs4UcggEvMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/home",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexkVzAZJYabrMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new___en",
    path: "/new",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/new.vue").then(m => m.default || m)
  },
  {
    name: organization_45settingslZaF7QAwYhMeta?.name,
    path: "/organization-settings",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings.vue").then(m => m.default || m),
    children: [
  {
    name: account_45detailsuJLdFRL0YiMeta?.name,
    path: "account-details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details.vue").then(m => m.default || m),
    children: [
  {
    name: "organization-settings-account-details___en",
    path: "",
    meta: indexRgY74jRdy0Meta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/index.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-account-details-mfa___en",
    path: "mfa",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/mfa.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-account-details-password___en",
    path: "password",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/password.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-account-details-personal-details___en",
    path: "personal-details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/account-details/personal-details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organization-settings-bank-details___en",
    path: "bank-details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings___en",
    path: "",
    meta: indexw8RXEsfmNgMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/index.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-organization-details___en",
    path: "organization-details",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/organization-details.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-widget___en",
    path: "widget",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/organization-settings/widget.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-code___en",
    path: "/payments/:code()",
    meta: _91code_93Eppcc1bijnMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/payments/[code].vue").then(m => m.default || m)
  },
  {
    name: "payments___en",
    path: "/payments",
    meta: indexbxl16OmctDMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings___en",
    path: "/settings",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signupGfC7GbyQliMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "user-signup___en",
    path: "/user-signup",
    meta: user_45signupkmEvWhvbkjMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/user-signup.vue").then(m => m.default || m)
  },
  {
    name: withdrawalsWe1GuITdptMeta?.name,
    path: "/withdrawals",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/withdrawals.vue").then(m => m.default || m),
    children: [
  {
    name: "withdrawals-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/[id].vue").then(m => m.default || m)
  },
  {
    name: "withdrawals___en",
    path: "",
    meta: indexAbyHVG4V8QMeta || {},
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/index.vue").then(m => m.default || m)
  },
  {
    name: "withdrawals-list___en",
    path: "list",
    component: () => import("/codebuild/output/src450006979/src/Web/Website/pages/withdrawals/list.vue").then(m => m.default || m)
  }
]
  }
]