import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error';
import type { ServerError } from '@apollo/client/link/utils';
import { authToken } from '~/services/get-user';
import { setBanner } from '~/services/index';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    const code = (networkError as ServerError).statusCode;
    console.error(`[Network error]: ${networkError}`);
    if (code === 401) {
      setBanner('Session Expired: Please refresh the screen by clicking <a href="">here</a> to reload the page.', false)
    }
  }
});
const customErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    const code = (networkError as ServerError).statusCode;
    console.error(`[Network error]: ${networkError}`);
    if (code === 401) {
      setBanner('Something went wrong, please try again later')
    }
  }
});
export const getClient = () => {
  const runtimeConfig = useRuntimeConfig()
  const headers: any = {};
  headers.Authorization = authToken.value;
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, createHttpLink({
      uri: runtimeConfig.public.VITE_USER_DATA_API,
      headers
    })])
  });
};

export const getDataProvider = () => {
  const key = 'X-Api-Key';
  const headers: any = {};
  const runtimeConfig = useRuntimeConfig()
  headers[key] = runtimeConfig.public.VITE_USER_DATA_API_KEY;
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, createHttpLink({
      uri: runtimeConfig.public.VITE_USER_DATA_API,
      headers
    })])
  });
};
export const getTransactionsProvider = () => {
  const key = 'Authorization';
  const headers: any = {};
  const runtimeConfig = useRuntimeConfig()
  headers[key] = 'custom-authorized';
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([customErrorLink, createHttpLink({
      uri: runtimeConfig.public.VITE_USER_DATA_API,
      headers
    })])
  });
};
