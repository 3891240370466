import gql from 'graphql-tag';
import { provideApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { OrganizationTransaction } from '~/models-ui';

const query = gql`

    query getOrganizationTransaction($id: String!) {
      getOrganizationTransaction(id: $id) {
            updatedAt
            createdAt
            chainTokenId
            amount
            address
            fiatAmount
            accountNumberLast4
            fiatCurrency
            error
            id
            status
            organizationId
            organizationName
            netAmount
            netFiatAmount
        }
    }`

export const getWithdrawalsById = (data: any): ComputedRef<OrganizationTransaction> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getOrganizationTransaction ?? {});
}
const mutation = gql`
  mutation updateTransactionStatus($id: String!) {
    updateTransactionStatus(status: completed, transactionId: $id)
  }`

export const CompleteTransactionStatus = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
