import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { StakingPoolByOrganization } from '~/models-ui';

const query = gql`
    query getStakingList($id: String!) {
        getOrganizationStakingPools(organizationId: $id) {
            id
            name
            numberOfContributors
            stakedAmount
            donatedApyAmount
            type
        }
    }`

// eslint-disable-next-line import/no-mutable-exports
export let dataStakingLoading = ref(false)
export const getStakingByOrgId = (data: any) : ComputedRef<Array<StakingPoolByOrganization>> => {
  dataStakingLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  dataStakingLoading = loading
  return computed(() => result.value?.getOrganizationStakingPools ?? []);
}
