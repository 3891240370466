<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError,
  statusCode: {
    type: Number,
    default: 500
  },
  statusMessage: {
    type: String,
    default: 'Server error'
  }
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div
    class="font-sans antialiased bg-white px-10 pt-14 dark:bg-black text-black dark:text-white min-h-screen flex flex-col"
  >
    <section>
      <h1 class="error-title">
        {{ props?.error?.statusCode || props.statusCode }} {{ props?.error?.statusMessage || props.statusMessage }}
      </h1>
      <div class="error-content">
        <div class="error-action">
          <p class="error-action-text">
            Sorry, <br>something <br> went wrong.
          </p>
          <button class="handle-error-btn" @click="handleError">
            Return Home
          </button>
        </div>
        <div class="error-img">
          <svg xmlns="http://www.w3.org/2000/svg" width="376" height="302" viewBox="0 0 376 302" fill="none">
            <g filter="url(#filter0_d_2_1548)">
              <path d="M220.857 94.6635C241.196 106.329 254.891 128.255 254.891 153.377C254.891 190.732 224.611 221.018 187.25 221.018C149.89 221.018 119.609 190.737 119.609 153.377C119.609 128.25 133.31 106.324 153.643 94.6635" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M278.665 210.77C259.514 241.051 225.73 261.16 187.256 261.16C169.744 261.16 153.206 256.996 138.573 249.604" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M263.772 76.7471C278.681 91.6922 289.238 110.984 293.387 132.551" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M147.282 52.6285C159.645 47.704 173.133 44.9971 187.25 44.9971C207.771 44.9971 226.953 50.718 243.299 60.6451" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M90.0105 200.305C83.0663 186.037 79.1725 170.009 79.1725 153.075C79.1725 117.771 96.101 86.4178 122.285 66.6939" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M215.543 9.93286C282.57 23.1081 333.136 82.1861 333.136 153.075C333.136 212.528 297.577 263.673 246.563 286.4" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M82.3011 254.403C56.9604 228.162 41.3698 192.441 41.3698 153.076C41.3698 72.51 106.684 7.19577 187.25 7.19577C188.463 7.19577 189.676 7.21139 190.884 7.24262" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M220.399 295.177C209.749 297.65 198.656 298.962 187.25 298.962C154.83 298.962 124.883 288.389 100.666 270.498" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M289.873 69.1991C304.506 69.1991 316.369 57.3363 316.369 42.7028C316.369 28.0693 304.506 16.2065 289.873 16.2065C275.239 16.2065 263.377 28.0693 263.377 42.7028C263.377 57.3363 275.239 69.1991 289.873 69.1991Z" fill="white" />
              <path d="M289.873 69.1991C304.506 69.1991 316.369 57.3363 316.369 42.7028C316.369 28.0693 304.506 16.2065 289.873 16.2065C275.239 16.2065 263.377 28.0693 263.377 42.7028C263.377 57.3363 275.239 69.1991 289.873 69.1991Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M297.869 35.1011C298.701 34.185 299.191 32.9512 299.128 31.603C299.019 29.1512 297.051 27.1418 294.605 26.9805C292.517 26.8399 290.695 28.0216 289.873 29.7654C289.05 28.0216 287.228 26.8451 285.141 26.9805C282.694 27.1418 280.727 29.1512 280.617 31.603C280.555 32.9512 281.044 34.185 281.877 35.1011L288.217 42.4983C289.087 43.5133 290.664 43.5133 291.533 42.4983L297.874 35.1011H297.869Z" fill="#20F3E0" />
              <mask
                id="mask0_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="280"
                y="26"
                width="20"
                height="18"
              >
                <path d="M297.869 35.0991C298.701 34.183 299.191 32.9492 299.128 31.601C299.019 29.1492 297.051 27.1398 294.605 26.9785C292.517 26.8379 290.695 28.0196 289.873 29.7634C289.05 28.0196 287.228 26.8431 285.141 26.9785C282.694 27.1398 280.727 29.1492 280.617 31.601C280.555 32.9492 281.044 34.183 281.877 35.0991L288.217 42.4962C289.087 43.5113 290.664 43.5113 291.533 42.4962L297.874 35.0991H297.869Z" fill="white" />
              </mask>
              <g mask="url(#mask0_2_1548)">
                <path d="M284.749 37.2536C286.942 33.6815 286.857 29.6419 284.559 28.2308C282.261 26.8198 278.62 28.5717 276.427 32.1438C274.233 35.7158 274.318 39.7555 276.616 41.1665C278.914 42.5775 282.555 40.8256 284.749 37.2536Z" fill="white" />
              </g>
              <path d="M284.204 37.8133L288.217 42.4983C289.087 43.5134 290.664 43.5134 291.533 42.4983L297.874 35.1012C298.707 34.1851 299.196 32.9513 299.134 31.6031C299.024 29.1513 297.056 27.1419 294.61 26.9806C292.522 26.84 290.701 28.0217 289.878 29.7655C289.056 28.0217 287.234 26.8452 285.146 26.9806C282.7 27.1419 280.732 29.1513 280.623 31.6031C280.56 32.9513 281.049 34.1851 281.882 35.1012" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M292.45 54.7902H299.873V57.3097C299.873 57.9604 299.342 58.4914 298.691 58.4914H293.631C292.981 58.4914 292.45 57.9604 292.45 57.3097V54.7902Z" fill="#00A0FF" />
              <path d="M299.878 54.79C299.878 54.1497 300.107 53.5303 300.523 53.0462L302.777 50.4174C304.235 48.7203 305.031 46.5548 305.031 44.3216V35.1078C305.031 34.3478 304.48 33.6763 303.725 33.5982C302.944 33.5201 302.252 34.0667 302.137 34.8215L300.43 41.9739C299.852 41.495 299.009 41.495 298.431 42L294.938 45.0556C293.355 46.4403 292.45 48.4392 292.45 50.5371V54.79H299.873H299.878Z" fill="white" />
              <path d="M292.45 55.0876V57.3364C292.45 57.9715 292.965 58.4869 293.6 58.4869H298.727C299.363 58.4869 299.878 57.9715 299.878 57.3364V54.7857C299.878 54.1454 300.107 53.526 300.523 53.0418L302.777 50.413C304.235 48.716 305.031 46.5505 305.031 44.3173V35.0462C305.031 34.2446 304.381 33.5887 303.574 33.5887C302.856 33.5887 302.246 34.1144 302.137 34.8224L300.487 41.7197" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M298.228 48.3571L300.778 43.9324C301.127 43.3285 301.023 42.5685 300.534 42.074C299.961 41.5014 299.04 41.4702 298.431 42.0011L294.938 45.0568C293.355 46.4415 292.45 48.4404 292.45 50.5383V52.178" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M279.868 54.7902H287.291V57.3097C287.291 57.9604 286.76 58.4914 286.109 58.4914H281.049C280.399 58.4914 279.868 57.9604 279.868 57.3097V54.7902Z" fill="#00A0FF" />
              <path d="M287.296 54.7911V50.5381C287.296 48.4351 286.39 46.4413 284.808 45.0567L281.315 42.001C280.742 41.5013 279.894 41.496 279.316 41.975L277.609 34.8225C277.489 34.0625 276.802 33.5159 276.021 33.5992C275.266 33.6773 274.714 34.3488 274.714 35.1088V44.3227C274.714 46.5611 275.516 48.7214 276.968 50.4184L279.222 53.0472C279.639 53.5313 279.868 54.1508 279.868 54.7911H287.291H287.296Z" fill="white" />
              <path d="M279.259 41.7197L277.609 34.8224C277.499 34.1144 276.89 33.5887 276.172 33.5887C275.37 33.5887 274.714 34.2393 274.714 35.0462V44.3173C274.714 46.5557 275.516 48.716 276.968 50.413L279.222 53.0418C279.639 53.526 279.868 54.1454 279.868 54.7857V57.3364C279.868 57.9715 280.383 58.4869 281.018 58.4869H286.146C286.781 58.4869 287.296 57.9715 287.296 57.3364V50.5328C287.296 48.4297 286.39 46.4308 284.808 45.0513L281.315 41.9956C280.706 41.4647 279.784 41.4907 279.212 42.0685C278.717 42.563 278.618 43.3231 278.967 43.9269L281.518 48.3516" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M305.375 257.911C315.052 257.911 322.897 250.066 322.897 240.389C322.897 230.712 315.052 222.867 305.375 222.867C295.698 222.867 287.853 230.712 287.853 240.389C287.853 250.066 295.698 257.911 305.375 257.911Z" fill="#04D6F1" />
              <path d="M305.375 257.911C315.052 257.911 322.897 250.066 322.897 240.389C322.897 230.712 315.052 222.867 305.375 222.867C295.698 222.867 287.853 230.712 287.853 240.389C287.853 250.066 295.698 257.911 305.375 257.911Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M303.996 243.312H306.864C307.437 243.312 307.905 243.781 307.905 244.353V247.419H302.955V244.353C302.955 243.781 303.423 243.312 303.996 243.312Z" fill="white" />
              <path d="M302.955 247.419V244.114C302.955 243.671 303.314 243.312 303.756 243.312H307.103C307.546 243.312 307.905 243.671 307.905 244.114V247.419" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M295.958 237.969H298.826C299.399 237.969 299.868 238.437 299.868 239.01V247.417H294.917V239.01C294.917 238.437 295.386 237.969 295.958 237.969Z" fill="#04D6F1" />
              <path d="M294.917 247.417V238.77C294.917 238.328 295.276 237.969 295.719 237.969H299.066C299.508 237.969 299.868 238.328 299.868 238.77V247.417" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M312.028 233.628H314.896C315.469 233.628 315.937 234.097 315.937 234.67V247.418H310.987V234.67C310.987 234.097 311.455 233.628 312.028 233.628Z" fill="#00A0FF" />
              <path d="M310.987 247.418V234.43C310.987 233.988 311.346 233.628 311.788 233.628H315.136C315.578 233.628 315.937 233.988 315.937 234.43V247.418" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M294.917 247.417H315.828" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M57.3144 117.388C69.5128 117.388 79.4016 107.499 79.4016 95.3007C79.4016 83.1022 69.5128 73.2135 57.3144 73.2135C45.116 73.2135 35.2272 83.1022 35.2272 95.3007C35.2272 107.499 45.116 117.388 57.3144 117.388Z" fill="#265AF2" />
              <path d="M57.3144 117.388C69.5128 117.388 79.4016 107.499 79.4016 95.3007C79.4016 83.1022 69.5128 73.2135 57.3144 73.2135C45.116 73.2135 35.2272 83.1022 35.2272 95.3007C35.2272 107.499 45.116 117.388 57.3144 117.388Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M120.52 168.471C122.881 168.471 124.794 166.557 124.794 164.197C124.794 161.837 122.881 159.923 120.52 159.923C118.16 159.923 116.247 161.837 116.247 164.197C116.247 166.557 118.16 168.471 120.52 168.471Z" fill="white" />
              <path d="M120.52 168.471C122.881 168.471 124.794 166.557 124.794 164.197C124.794 161.837 122.881 159.923 120.52 159.923C118.16 159.923 116.247 161.837 116.247 164.197C116.247 166.557 118.16 168.471 120.52 168.471Z" fill="white" />
              <path d="M120.52 168.471C122.881 168.471 124.794 166.557 124.794 164.197C124.794 161.837 122.881 159.923 120.52 159.923C118.16 159.923 116.247 161.837 116.247 164.197C116.247 166.557 118.16 168.471 120.52 168.471Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M283.954 109.994C287.686 109.994 290.711 106.969 290.711 103.237C290.711 99.5055 287.686 96.4804 283.954 96.4804C280.223 96.4804 277.197 99.5055 277.197 103.237C277.197 106.969 280.223 109.994 283.954 109.994Z" fill="white" />
              <path d="M283.954 109.994C287.686 109.994 290.711 106.969 290.711 103.237C290.711 99.5055 287.686 96.4804 283.954 96.4804C280.223 96.4804 277.197 99.5055 277.197 103.237C277.197 106.969 280.223 109.994 283.954 109.994Z" fill="#20F3E0" />
              <path d="M283.954 109.994C287.686 109.994 290.711 106.969 290.711 103.237C290.711 99.5055 287.686 96.4804 283.954 96.4804C280.223 96.4804 277.197 99.5055 277.197 103.237C277.197 106.969 280.223 109.994 283.954 109.994Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M190.884 14.5137C194.616 14.5137 197.641 11.4885 197.641 7.75685C197.641 4.02516 194.616 1.00003 190.884 1.00003C187.152 1.00003 184.127 4.02516 184.127 7.75685C184.127 11.4885 187.152 14.5137 190.884 14.5137Z" fill="white" />
              <path d="M190.884 14.5137C194.616 14.5137 197.641 11.4885 197.641 7.75685C197.641 4.02516 194.616 1.00003 190.884 1.00003C187.152 1.00003 184.127 4.02516 184.127 7.75685C184.127 11.4885 187.152 14.5137 190.884 14.5137Z" fill="white" />
              <path d="M190.884 14.5137C194.616 14.5137 197.641 11.4885 197.641 7.75685C197.641 4.02516 194.616 1.00003 190.884 1.00003C187.152 1.00003 184.127 4.02516 184.127 7.75685C184.127 11.4885 187.152 14.5137 190.884 14.5137Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M346.098 69.5639C349.83 69.5639 352.855 66.5387 352.855 62.8071C352.855 59.0754 349.83 56.0502 346.098 56.0502C342.366 56.0502 339.341 59.0754 339.341 62.8071C339.341 66.5387 342.366 69.5639 346.098 69.5639Z" fill="white" />
              <path d="M346.098 69.5639C349.83 69.5639 352.855 66.5387 352.855 62.8071C352.855 59.0754 349.83 56.0502 346.098 56.0502C342.366 56.0502 339.341 59.0754 339.341 62.8071C339.341 66.5387 342.366 69.5639 346.098 69.5639Z" fill="#265AF2" />
              <path d="M346.098 69.5639C349.83 69.5639 352.855 66.5387 352.855 62.8071C352.855 59.0754 349.83 56.0502 346.098 56.0502C342.366 56.0502 339.341 59.0754 339.341 62.8071C339.341 66.5387 342.366 69.5639 346.098 69.5639Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M19.6885 229.811C23.4202 229.811 26.4453 226.786 26.4453 223.054C26.4453 219.323 23.4202 216.298 19.6885 216.298C15.9568 216.298 12.9317 219.323 12.9317 223.054C12.9317 226.786 15.9568 229.811 19.6885 229.811Z" fill="white" />
              <path d="M19.6885 229.811C23.4202 229.811 26.4453 226.786 26.4453 223.054C26.4453 219.323 23.4202 216.298 19.6885 216.298C15.9568 216.298 12.9317 219.323 12.9317 223.054C12.9317 226.786 15.9568 229.811 19.6885 229.811Z" fill="#00A0FF" />
              <path d="M19.6885 229.811C23.4202 229.811 26.4453 226.786 26.4453 223.054C26.4453 219.323 23.4202 216.298 19.6885 216.298C15.9568 216.298 12.9317 219.323 12.9317 223.054C12.9317 226.786 15.9568 229.811 19.6885 229.811Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M41.3697 173.998C48.5025 173.998 54.2847 168.216 54.2847 161.083C54.2847 153.95 48.5025 148.168 41.3697 148.168C34.237 148.168 28.4547 153.95 28.4547 161.083C28.4547 168.216 34.237 173.998 41.3697 173.998Z" fill="white" />
              <path d="M41.3697 173.998C48.5025 173.998 54.2847 168.216 54.2847 161.083C54.2847 153.95 48.5025 148.168 41.3697 148.168C34.237 148.168 28.4547 153.95 28.4547 161.083C28.4547 168.216 34.237 173.998 41.3697 173.998Z" fill="#20F3E0" />
              <mask
                id="mask1_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="28"
                y="148"
                width="27"
                height="27"
              >
                <path d="M41.3697 174.001C48.5025 174.001 54.2847 168.218 54.2847 161.086C54.2847 153.953 48.5025 148.171 41.3697 148.171C34.237 148.171 28.4547 153.953 28.4547 161.086C28.4547 168.218 34.237 174.001 41.3697 174.001Z" fill="white" />
              </mask>
              <g mask="url(#mask1_2_1548)">
                <path d="M42.3417 158.28C45.2887 153.48 45.1751 148.053 42.0881 146.157C39.0011 144.262 34.1097 146.616 31.1628 151.416C28.2159 156.215 28.3294 161.643 31.4164 163.538C34.5034 165.433 39.3948 163.079 42.3417 158.28Z" fill="white" />
              </g>
              <path d="M41.3697 173.998C48.5025 173.998 54.2847 168.216 54.2847 161.083C54.2847 153.95 48.5025 148.168 41.3697 148.168C34.237 148.168 28.4547 153.95 28.4547 161.083C28.4547 168.216 34.237 173.998 41.3697 173.998Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M35.2272 50.5021C41.0662 50.5021 45.7997 45.7686 45.7997 39.9296C45.7997 34.0906 41.0662 29.3571 35.2272 29.3571C29.3881 29.3571 24.6547 34.0906 24.6547 39.9296C24.6547 45.7686 29.3881 50.5021 35.2272 50.5021Z" fill="white" />
              <path d="M35.2272 50.5021C41.0662 50.5021 45.7997 45.7686 45.7997 39.9296C45.7997 34.0906 41.0662 29.3571 35.2272 29.3571C29.3881 29.3571 24.6547 34.0906 24.6547 39.9296C24.6547 45.7686 29.3881 50.5021 35.2272 50.5021Z" fill="white" />
              <path d="M35.2272 50.5021C41.0662 50.5021 45.7997 45.7686 45.7997 39.9296C45.7997 34.0906 41.0662 29.3571 35.2272 29.3571C29.3881 29.3571 24.6547 34.0906 24.6547 39.9296C24.6547 45.7686 29.3881 50.5021 35.2272 50.5021Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M363.427 235.229C369.267 235.229 374 230.496 374 224.657C374 218.818 369.267 214.084 363.427 214.084C357.588 214.084 352.855 218.818 352.855 224.657C352.855 230.496 357.588 235.229 363.427 235.229Z" fill="white" />
              <path d="M363.427 235.229C369.267 235.229 374 230.496 374 224.657C374 218.818 369.267 214.084 363.427 214.084C357.588 214.084 352.855 218.818 352.855 224.657C352.855 230.496 357.588 235.229 363.427 235.229Z" fill="white" />
              <path d="M363.427 235.229C369.267 235.229 374 230.496 374 224.657C374 218.818 369.267 214.084 363.427 214.084C357.588 214.084 352.855 218.818 352.855 224.657C352.855 230.496 357.588 235.229 363.427 235.229Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M229.16 212.81C232.892 212.81 235.917 209.785 235.917 206.053C235.917 202.321 232.892 199.296 229.16 199.296C225.429 199.296 222.404 202.321 222.404 206.053C222.404 209.785 225.429 212.81 229.16 212.81Z" fill="white" />
              <path d="M229.16 212.81C232.892 212.81 235.917 209.785 235.917 206.053C235.917 202.321 232.892 199.296 229.16 199.296C225.429 199.296 222.404 202.321 222.404 206.053C222.404 209.785 225.429 212.81 229.16 212.81Z" fill="#00A0FF" />
              <path d="M229.16 212.81C232.892 212.81 235.917 209.785 235.917 206.053C235.917 202.321 232.892 199.296 229.16 199.296C225.429 199.296 222.404 202.321 222.404 206.053C222.404 209.785 225.429 212.81 229.16 212.81Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M245.912 290.675C248.272 290.675 250.186 288.761 250.186 286.401C250.186 284.041 248.272 282.127 245.912 282.127C243.552 282.127 241.638 284.041 241.638 286.401C241.638 288.761 243.552 290.675 245.912 290.675Z" fill="white" />
              <path d="M245.912 290.675C248.272 290.675 250.186 288.761 250.186 286.401C250.186 284.041 248.272 282.127 245.912 282.127C243.552 282.127 241.638 284.041 241.638 286.401C241.638 288.761 243.552 290.675 245.912 290.675Z" fill="white" />
              <path d="M245.912 290.675C248.272 290.675 250.186 288.761 250.186 286.401C250.186 284.041 248.272 282.127 245.912 282.127C243.552 282.127 241.638 284.041 241.638 286.401C241.638 288.761 243.552 290.675 245.912 290.675Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M5.925 214.694C8.09271 214.694 9.84999 212.936 9.84999 210.769C9.84999 208.601 8.09271 206.844 5.925 206.844C3.75728 206.844 2 208.601 2 210.769C2 212.936 3.75728 214.694 5.925 214.694Z" fill="white" />
              <path d="M5.925 214.694C8.09271 214.694 9.84999 212.936 9.84999 210.769C9.84999 208.601 8.09271 206.844 5.925 206.844C3.75728 206.844 2 208.601 2 210.769C2 212.936 3.75728 214.694 5.925 214.694Z" fill="white" />
              <path d="M5.925 214.694C8.09271 214.694 9.84999 212.936 9.84999 210.769C9.84999 208.601 8.09271 206.844 5.925 206.844C3.75728 206.844 2 208.601 2 210.769C2 212.936 3.75728 214.694 5.925 214.694Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M357.53 54.0934C358.795 54.0934 359.82 53.068 359.82 51.803C359.82 50.538 358.795 49.5125 357.53 49.5125C356.265 49.5125 355.239 50.538 355.239 51.803C355.239 53.068 356.265 54.0934 357.53 54.0934Z" fill="white" />
              <path d="M357.53 54.0934C358.795 54.0934 359.82 53.068 359.82 51.803C359.82 50.538 358.795 49.5125 357.53 49.5125C356.265 49.5125 355.239 50.538 355.239 51.803C355.239 53.068 356.265 54.0934 357.53 54.0934Z" fill="white" />
              <path d="M357.53 54.0934C358.795 54.0934 359.82 53.068 359.82 51.803C359.82 50.538 358.795 49.5125 357.53 49.5125C356.265 49.5125 355.239 50.538 355.239 51.803C355.239 53.068 356.265 54.0934 357.53 54.0934Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M209.473 64.7681C218.799 64.7681 226.36 57.2076 226.36 47.8812C226.36 38.5549 218.799 30.9944 209.473 30.9944C200.147 30.9944 192.586 38.5549 192.586 47.8812C192.586 57.2076 200.147 64.7681 209.473 64.7681Z" fill="#00A0FF" />
              <path d="M209.473 64.7681C218.799 64.7681 226.36 57.2076 226.36 47.8812C226.36 38.5549 218.799 30.9944 209.473 30.9944C200.147 30.9944 192.586 38.5549 192.586 47.8812C192.586 57.2076 200.147 64.7681 209.473 64.7681Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M211.321 47.1961V39.4346C211.321 39.0598 210.962 38.7943 210.603 38.9036L202.586 41.3086C202.175 41.4335 201.894 41.8135 201.894 42.2404V50.0279C201.607 49.9186 201.3 49.8561 200.978 49.8561C199.567 49.8561 198.422 51.0013 198.422 52.412C198.422 53.8227 199.567 54.968 200.978 54.968C202.388 54.968 203.533 53.8227 203.533 52.412V44.4215L209.681 42.5787V47.1961H211.321Z" fill="#00A0FF" />
              <path d="M211.321 41.9741V39.4286C211.321 39.0538 210.962 38.7883 210.603 38.8976L202.586 41.3026C202.175 41.4275 201.894 41.8075 201.894 42.2344V50.0219C201.607 49.9126 201.3 49.8501 200.978 49.8501C199.567 49.8501 198.422 50.9953 198.422 52.406C198.422 53.8168 199.567 54.962 200.978 54.962C202.388 54.962 203.533 53.8168 203.533 52.406V44.4155L206.829 43.3796" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M218.395 39.95L210.379 42.3549C209.968 42.4799 209.686 42.8599 209.686 43.2867V51.0743C209.4 50.9649 209.093 50.9025 208.77 50.9025C207.36 50.9025 206.214 52.0477 206.214 53.4584C206.214 54.8691 207.36 56.0143 208.77 56.0143C210.181 56.0143 211.326 54.8691 211.326 53.4584V45.4679L217.474 43.6251V48.2424C217.188 48.1331 216.881 48.0706 216.558 48.0706C215.147 48.0706 214.002 49.2159 214.002 50.6266C214.002 52.0373 215.147 53.1825 216.558 53.1825C217.969 53.1825 219.114 52.0373 219.114 50.6266V40.4757C219.114 40.1009 218.755 39.8354 218.395 39.9448V39.95Z" fill="white" />
              <mask
                id="mask2_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="206"
                y="39"
                width="14"
                height="18"
              >
                <path d="M218.395 39.9514L210.379 42.3563C209.968 42.4813 209.686 42.8613 209.686 43.2881V51.0757C209.4 50.9663 209.093 50.9039 208.77 50.9039C207.36 50.9039 206.214 52.0491 206.214 53.4598C206.214 54.8705 207.36 56.0157 208.77 56.0157C210.181 56.0157 211.326 54.8705 211.326 53.4598V45.4693L217.474 43.6265V48.2438C217.188 48.1345 216.881 48.072 216.558 48.072C215.147 48.072 214.002 49.2173 214.002 50.628C214.002 52.0387 215.147 53.1839 216.558 53.1839C217.969 53.1839 219.114 52.0387 219.114 50.628V40.4771C219.114 40.1023 218.755 39.8368 218.395 39.9462V39.9514Z" fill="white" />
              </mask>
              <g mask="url(#mask2_2_1548)">
                <path d="M224.841 46.9725C226.884 44.3585 224.988 39.4637 220.607 36.0398C216.227 32.6159 211.019 31.9593 208.976 34.5733C206.933 37.1873 208.828 42.0821 213.209 45.506C217.59 48.93 222.797 49.5865 224.841 46.9725Z" fill="#20F3E0" />
              </g>
              <path d="M211.326 45.4726V53.4631C211.326 54.8739 210.181 56.0191 208.77 56.0191C207.36 56.0191 206.214 54.8739 206.214 53.4631C206.214 52.0524 207.36 50.9072 208.77 50.9072C209.093 50.9072 209.4 50.9697 209.686 51.079V43.2915C209.686 42.8594 209.968 42.4794 210.379 42.3597L218.395 39.9547C218.755 39.8454 219.114 40.1161 219.114 40.4857V50.6365C219.114 52.0472 217.969 53.1925 216.558 53.1925C215.147 53.1925 214.002 52.0472 214.002 50.6365C214.002 49.2258 215.147 48.0806 216.558 48.0806C216.881 48.0806 217.188 48.1431 217.474 48.2524V43.635L211.326 45.4778V45.4726Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <mask
                id="mask3_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="41"
                y="84"
                width="32"
                height="22"
              >
                <path d="M70.6406 89.5453C70.1356 86.7812 67.7515 84.6677 64.852 84.5948H49.938C46.9709 84.5948 44.5086 86.7291 43.9933 89.5505L41.7393 101.362C41.3332 103.496 42.9678 105.474 45.1437 105.474C46.0183 105.474 46.8616 105.141 47.5018 104.548L51.9057 100.446C52.4732 99.9148 53.2228 99.6233 53.9984 99.6233H60.6303C61.4059 99.6233 62.1555 99.92 62.7229 100.446L67.1268 104.548C67.7671 105.146 68.6104 105.474 69.4849 105.474C71.6557 105.474 73.2954 103.496 72.8894 101.362L70.6354 89.5505L70.6406 89.5453Z" fill="white" />
              </mask>
              <g mask="url(#mask3_2_1548)">
                <path d="M70.6404 89.5453C70.1355 86.7812 67.7514 84.6677 64.8519 84.5948H49.9379C46.9708 84.5948 44.5085 86.7291 43.9932 89.5505L41.7392 101.362C41.3331 103.496 42.9677 105.474 45.1436 105.474C46.0181 105.474 46.8614 105.141 47.5017 104.548L51.9056 100.446C52.473 99.9148 53.2226 99.6233 53.9983 99.6233H60.6302C61.4058 99.6233 62.1554 99.92 62.7228 100.446L67.1267 104.548C67.767 105.146 68.6103 105.474 69.4848 105.474C71.6555 105.474 73.2953 103.496 72.8893 101.362L70.6352 89.5505L70.6404 89.5453Z" fill="white" />
                <path d="M70.6404 89.5453C70.1355 86.7812 67.7514 84.6677 64.8519 84.5948H49.9379C46.9708 84.5948 44.5085 86.7291 43.9932 89.5505L41.7392 101.362C41.3331 103.496 42.9677 105.474 45.1436 105.474C46.0181 105.474 46.8614 105.141 47.5017 104.548L51.9056 100.446C52.473 99.9148 53.2226 99.6233 53.9983 99.6233H60.6302C61.4058 99.6233 62.1554 99.92 62.7228 100.446L67.1267 104.548C67.767 105.146 68.6103 105.474 69.4848 105.474C71.6555 105.474 73.2953 103.496 72.8893 101.362L70.6352 89.5505L70.6404 89.5453Z" fill="white" />
                <path d="M51.9065 91.4367C56.7113 85.289 57.4003 77.7995 53.4452 74.7083C49.4902 71.6172 42.389 74.0951 37.5841 80.2428C32.7793 86.3905 32.0903 93.8801 36.0453 96.9712C40.0004 100.062 47.1016 97.5844 51.9065 91.4367Z" fill="#20F3E0" />
              </g>
              <path d="M70.6406 89.5433C70.1356 86.7792 67.7515 84.6657 64.852 84.5928H49.938C46.9709 84.5928 44.5086 86.7271 43.9933 89.5485L41.7393 101.36C41.3332 103.494 42.9678 105.472 45.1437 105.472C46.0183 105.472 46.8616 105.139 47.5018 104.546L51.9057 100.444C52.4732 99.9128 53.2228 99.6213 53.9984 99.6213H60.6303C61.4059 99.6213 62.1555 99.918 62.7229 100.444L67.1268 104.546C67.7671 105.144 68.6104 105.472 69.4849 105.472C71.6557 105.472 73.2954 103.494 72.8894 101.36L70.6354 89.5485L70.6406 89.5433Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M54.4617 92.9781V91.8068C54.4617 91.4216 54.1493 91.1093 53.7641 91.1093H51.9734V89.3185C51.9734 88.9333 51.6611 88.621 51.2759 88.621H50.1046C49.7194 88.621 49.4071 88.9333 49.4071 89.3185V91.1093H47.6164C47.2312 91.1093 46.9188 91.4216 46.9188 91.8068V92.9781C46.9188 93.3633 47.2312 93.6756 47.6164 93.6756H49.4071V95.4663C49.4071 95.8515 49.7194 96.1639 50.1046 96.1639H51.2759C51.6611 96.1639 51.9734 95.8515 51.9734 95.4663V93.6756" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M63.8525 96.483C64.531 96.483 65.0811 95.9329 65.0811 95.2545C65.0811 94.576 64.531 94.0259 63.8525 94.0259C63.174 94.0259 62.624 94.576 62.624 95.2545C62.624 95.9329 63.174 96.483 63.8525 96.483Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M66.7207 93.6137C67.3992 93.6137 67.9492 93.0637 67.9492 92.3852C67.9492 91.7067 67.3992 91.1567 66.7207 91.1567C66.0422 91.1567 65.4922 91.7067 65.4922 92.3852C65.4922 93.0637 66.0422 93.6137 66.7207 93.6137Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M63.8525 90.7468C64.531 90.7468 65.0811 90.1967 65.0811 89.5182C65.0811 88.8398 64.531 88.2897 63.8525 88.2897C63.174 88.2897 62.624 88.8398 62.624 89.5182C62.624 90.1967 63.174 90.7468 63.8525 90.7468Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M60.9841 93.6137C61.6626 93.6137 62.2126 93.0637 62.2126 92.3852C62.2126 91.7067 61.6626 91.1567 60.9841 91.1567C60.3056 91.1567 59.7556 91.7067 59.7556 92.3852C59.7556 93.0637 60.3056 93.6137 60.9841 93.6137Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M137.938 42.7015H102.811C101.661 42.7015 100.729 43.6338 100.729 44.7838V86.5428C100.729 87.6928 101.661 88.625 102.811 88.625H137.938C139.088 88.625 140.02 87.6928 140.02 86.5428V44.7838C140.02 43.6338 139.088 42.7015 137.938 42.7015Z" fill="#20F3E0" />
              <path d="M137.938 42.7015H102.811C101.661 42.7015 100.729 43.6338 100.729 44.7838V82.1857C100.729 83.3357 101.661 84.268 102.811 84.268H137.938C139.088 84.268 140.02 83.3357 140.02 82.1857V44.7838C140.02 43.6338 139.088 42.7015 137.938 42.7015Z" fill="white" />
              <path d="M110.224 69.4948L118.443 55.9551C119.323 54.5079 121.421 54.5079 122.301 55.9551L130.52 69.4948C131.431 70.9992 130.348 72.92 128.589 72.92H112.145C110.385 72.92 109.302 70.994 110.213 69.4948H110.224Z" fill="#00A0FF" />
              <path d="M111.119 72.9207L118.912 60.0838C119.578 58.9906 121.166 58.9906 121.832 60.0838L129.625 72.9207H111.119Z" fill="#04D6F1" />
              <path d="M110.224 69.4948L118.443 55.9551C119.323 54.5079 121.421 54.5079 122.301 55.9551L130.52 69.4948C131.431 70.9992 130.348 72.92 128.589 72.92H112.145C110.385 72.92 109.302 70.994 110.213 69.4948H110.224Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M137.938 42.7015H102.811C101.661 42.7015 100.729 43.6338 100.729 44.7838V86.5428C100.729 87.6928 101.661 88.625 102.811 88.625H137.938C139.088 88.625 140.02 87.6928 140.02 86.5428V44.7838C140.02 43.6338 139.088 42.7015 137.938 42.7015Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M111.551 84.2637H103.894C102.145 84.2637 100.729 82.8478 100.729 81.0988" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M140.02 81.1029C140.02 82.852 138.604 84.2679 136.855 84.2679H115.226" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M221.206 127.593C225.694 134.35 228.322 142.507 228.322 151.299C228.322 174.573 209.936 193.444 187.256 193.444C164.575 193.444 146.189 174.573 146.189 151.299C146.189 142.507 148.818 134.345 153.305 127.593L178.843 87.3125C182.758 81.1439 191.758 81.1439 195.673 87.3125L221.212 127.593H221.206Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M181.363 180.023C193.547 180.023 203.424 170.146 203.424 157.962C203.424 145.778 193.547 135.901 181.363 135.901C169.179 135.901 159.302 145.778 159.302 157.962C159.302 170.146 169.179 180.023 181.363 180.023Z" fill="#04D6F1" />
              <mask
                id="mask4_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="159"
                y="135"
                width="45"
                height="46"
              >
                <path d="M181.363 180.025C193.547 180.025 203.424 170.148 203.424 157.964C203.424 145.78 193.547 135.903 181.363 135.903C169.179 135.903 159.302 145.78 159.302 157.964C159.302 170.148 169.179 180.025 181.363 180.025Z" fill="white" />
              </mask>
              <g mask="url(#mask4_2_1548)">
                <path d="M178.918 148.494C184.151 139.971 183.073 129.794 176.509 125.764C169.946 121.734 160.383 125.377 155.149 133.9C149.916 142.424 150.994 152.601 157.557 156.631C164.121 160.661 173.684 157.018 178.918 148.494Z" fill="white" />
              </g>
              <path d="M159.749 153.522C161.806 143.465 170.697 135.901 181.363 135.901C193.544 135.901 203.424 145.776 203.424 157.962C203.424 170.148 193.549 180.023 181.363 180.023C169.177 180.023 159.531 170.372 159.307 158.384" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M205.251 165.568C212.723 165.568 218.781 159.511 218.781 152.039C218.781 144.567 212.723 138.51 205.251 138.51C197.779 138.51 191.722 144.567 191.722 152.039C191.722 159.511 197.779 165.568 205.251 165.568Z" fill="#04D6F1" />
              <path d="M205.251 165.568C212.723 165.568 218.781 159.511 218.781 152.039C218.781 144.567 212.723 138.51 205.251 138.51C197.779 138.51 191.722 144.567 191.722 152.039C191.722 159.511 197.779 165.568 205.251 165.568Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M197.224 139.874C203.21 139.874 208.062 135.021 208.062 129.036C208.062 123.05 203.21 118.198 197.224 118.198C191.239 118.198 186.386 123.05 186.386 129.036C186.386 135.021 191.239 139.874 197.224 139.874Z" fill="#00A0FF" />
              <path d="M197.224 139.874C203.21 139.874 208.062 135.021 208.062 129.036C208.062 123.05 203.21 118.198 197.224 118.198C191.239 118.198 186.386 123.05 186.386 129.036C186.386 135.021 191.239 139.874 197.224 139.874Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M183.685 130.644C190.222 130.644 195.522 125.344 195.522 118.807C195.522 112.269 190.222 106.969 183.685 106.969C177.147 106.969 171.847 112.269 171.847 118.807C171.847 125.344 177.147 130.644 183.685 130.644Z" fill="#20F3E0" />
              <mask
                id="mask5_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="171"
                y="106"
                width="25"
                height="25"
              >
                <path d="M183.685 130.646C190.222 130.646 195.522 125.347 195.522 118.809C195.522 112.271 190.222 106.971 183.685 106.971C177.147 106.971 171.847 112.271 171.847 118.809C171.847 125.347 177.147 130.646 183.685 130.646Z" fill="white" />
              </mask>
              <g mask="url(#mask5_2_1548)">
                <path d="M184.197 120.334C189.431 111.81 188.353 101.634 181.789 97.6037C175.226 93.5737 165.662 97.2164 160.429 105.74C155.195 114.263 156.274 124.44 162.837 128.47C169.401 132.5 178.964 128.857 184.197 120.334Z" fill="white" />
              </g>
              <path d="M186.485 130.315C185.585 130.534 184.648 130.648 183.685 130.648C177.146 130.648 171.847 125.349 171.847 118.811C171.847 112.273 177.146 106.973 183.685 106.973C190.223 106.973 195.522 112.273 195.522 118.811C195.522 120.898 194.981 122.856 194.033 124.558" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M187.516 107.39C190.673 107.39 193.232 104.831 193.232 101.674C193.232 98.5173 190.673 95.9583 187.516 95.9583C184.359 95.9583 181.8 98.5173 181.8 101.674C181.8 104.831 184.359 107.39 187.516 107.39Z" fill="#20F3E0" />
              <path d="M187.516 107.39C190.673 107.39 193.232 104.831 193.232 101.674C193.232 98.5173 190.673 95.9583 187.516 95.9583C184.359 95.9583 181.8 98.5173 181.8 101.674C181.8 104.831 184.359 107.39 187.516 107.39Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M170.228 138.12C173.897 138.12 176.871 135.146 176.871 131.477C176.871 127.809 173.897 124.835 170.228 124.835C166.56 124.835 163.586 127.809 163.586 131.477C163.586 135.146 166.56 138.12 170.228 138.12Z" fill="#00A0FF" />
              <path d="M170.228 138.12C173.897 138.12 176.871 135.146 176.871 131.477C176.871 127.809 173.897 124.835 170.228 124.835C166.56 124.835 163.586 127.809 163.586 131.477C163.586 135.146 166.56 138.12 170.228 138.12Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M291.497 144.101L273.72 171.263C272.887 172.533 273.304 174.241 274.626 174.985L293.611 185.693C294.678 186.297 295.984 186.297 297.051 185.693L316.036 174.985C317.358 174.241 317.775 172.533 316.942 171.263L299.165 144.101C297.353 141.336 293.303 141.336 291.492 144.101H291.497Z" fill="white" />
              <path d="M274.626 174.991L293.61 185.699C294.678 186.303 295.984 186.303 297.051 185.699L316.036 174.991C316.104 174.954 316.166 174.913 316.229 174.871C317.712 173.861 317.535 171.602 315.974 170.712L297.369 160.129C296.104 159.416 294.563 159.416 293.298 160.129L274.693 170.712C273.132 171.597 272.955 173.856 274.438 174.871C274.501 174.913 274.563 174.954 274.631 174.991H274.626Z" fill="#20F3E0" />
              <path d="M279.019 187.202L292.866 205.556C294.1 207.196 296.562 207.196 297.796 205.556L311.643 187.202C312.762 185.718 311.049 183.766 309.435 184.682L297.228 191.564C296.052 192.23 294.61 192.23 293.433 191.564L281.226 184.682C279.608 183.771 277.9 185.723 279.019 187.202Z" fill="white" />
              <path d="M297.796 205.563L311.643 187.208C312.762 185.724 311.049 183.772 309.436 184.688L297.228 191.57C296.64 191.903 295.984 192.07 295.334 192.07V206.796C296.255 206.796 297.182 206.385 297.801 205.568L297.796 205.563Z" fill="#04D6F1" />
              <path d="M316.942 171.263L299.165 144.1C298.259 142.716 296.796 142.023 295.328 142.023V186.146C295.922 186.146 296.515 185.995 297.046 185.693L316.031 174.985C317.353 174.241 317.77 172.533 316.937 171.263H316.942Z" fill="#04D6F1" />
              <path d="M316.229 174.872C316.229 174.872 316.239 174.862 316.249 174.856C316.182 174.903 316.114 174.95 316.041 174.992C316.109 174.955 316.171 174.914 316.234 174.872H316.229Z" fill="#20F3E0" />
              <path d="M315.974 170.713L297.369 160.13C296.739 159.776 296.036 159.594 295.333 159.594V186.142C295.927 186.142 296.52 185.991 297.051 185.689L316.036 174.981C316.109 174.94 316.177 174.893 316.244 174.846C317.707 173.831 317.525 171.587 315.968 170.702L315.974 170.713Z" fill="#00A0FF" />
              <path d="M291.497 144.101L273.72 171.263C272.887 172.533 273.304 174.241 274.626 174.985L293.611 185.693C294.678 186.297 295.984 186.297 297.051 185.693L316.036 174.985C317.358 174.241 317.775 172.533 316.942 171.263L299.165 144.101C297.353 141.336 293.303 141.336 291.492 144.101H291.497Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M279.019 187.202L292.866 205.556C294.1 207.196 296.562 207.196 297.796 205.556L311.643 187.202C312.762 185.718 311.049 183.766 309.435 184.682L297.228 191.564C296.052 192.23 294.61 192.23 293.433 191.564L281.226 184.682C279.608 183.771 277.9 185.723 279.019 187.202Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M295.334 186.146V146.475" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M295.334 192.064V206.79" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M89.6825 176.674L79.9533 192.082C79.0944 193.441 79.5317 195.247 80.9216 196.059L91.7856 202.41C92.9256 203.077 94.3363 203.077 95.4763 202.41L106.34 196.059C107.73 195.247 108.167 193.446 107.309 192.082L97.5794 176.674C95.747 173.769 91.5097 173.769 89.6773 176.674H89.6825Z" fill="#20F3E0" />
              <path d="M107.22 191.962L97.5585 176.658C96.6476 175.216 95.1432 174.497 93.6335 174.497V202.712C94.2686 202.712 94.8985 202.545 95.4659 202.217L106.252 195.913C107.631 195.106 108.069 193.315 107.215 191.967L107.22 191.962Z" fill="#00A0FF" />
              <path d="M89.6825 176.674L79.9533 192.082C79.0944 193.441 79.5317 195.247 80.9216 196.059L91.7856 202.41C92.9256 203.077 94.3363 203.077 95.4763 202.41L106.34 196.059C107.73 195.247 108.167 193.446 107.309 192.082L97.5794 176.674C95.747 173.769 91.5097 173.769 89.6773 176.674H89.6825Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M108.912 199.874L95.6272 207.636C94.3935 208.354 92.8735 208.354 91.6398 207.636L78.3552 199.874C76.9289 199.041 75.0809 199.588 74.3625 201.077C72.9466 204.018 72.1501 207.323 72.1501 210.827C72.1501 223.003 81.7648 232.867 93.6283 232.867C105.492 232.867 115.106 222.997 115.106 210.827C115.106 207.329 114.31 204.018 112.894 201.077C112.176 199.588 110.328 199.041 108.901 199.874H108.912Z" fill="#04D6F1" />
              <mask
                id="mask6_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="72"
                y="199"
                width="44"
                height="34"
              >
                <path d="M108.912 199.873L95.6272 207.634C94.3935 208.353 92.8735 208.353 91.6398 207.634L78.3552 199.873C76.9289 199.04 75.0809 199.586 74.3625 201.075C72.9466 204.016 72.1501 207.322 72.1501 210.825C72.1501 223.001 81.7648 232.866 93.6283 232.866C105.492 232.866 115.106 222.996 115.106 210.825C115.106 207.327 114.31 204.016 112.894 201.075C112.176 199.586 110.328 199.04 108.901 199.873H108.912Z" fill="white" />
              </mask>
              <g mask="url(#mask6_2_1548)">
                <path d="M98.3469 225.983C106.336 212.971 106.026 198.256 97.6547 193.116C89.2831 187.975 76.02 194.357 68.0306 207.369C60.0413 220.38 60.3512 235.096 68.7228 240.236C77.0944 245.376 90.3576 238.995 98.3469 225.983Z" fill="white" />
              </g>
              <path d="M84.1542 203.263L78.3552 199.874C76.9289 199.041 75.0809 199.588 74.3625 201.077C72.9466 204.018 72.1501 207.323 72.1501 210.827C72.1501 223.003 81.7648 232.867 93.6283 232.867C105.492 232.867 115.106 222.997 115.106 210.827C115.106 207.329 114.31 204.018 112.894 201.077C112.176 199.588 110.328 199.041 108.901 199.874L95.6168 207.636C94.3831 208.354 92.8631 208.354 91.6294 207.636L87.6835 205.33" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M93.6335 202.91V174.737" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M172.456 278.646C184.341 278.646 193.976 269.011 193.976 257.126C193.976 245.241 184.341 235.606 172.456 235.606C160.571 235.606 150.936 245.241 150.936 257.126C150.936 269.011 160.571 278.646 172.456 278.646Z" fill="white" />
              <path d="M172.456 278.646C184.341 278.646 193.976 269.011 193.976 257.126C193.976 245.241 184.341 235.606 172.456 235.606C160.571 235.606 150.936 245.241 150.936 257.126C150.936 269.011 160.571 278.646 172.456 278.646Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M171.004 245.667C172.274 245.667 174.59 245.667 175.855 245.667C181.342 245.667 185.788 251.065 185.788 257.728C185.788 264.391 181.342 269.789 175.855 269.789H171.004C165.517 269.789 161.072 264.391 161.072 257.728C161.072 251.065 165.517 245.667 171.004 245.667Z" fill="#04D6F1" />
              <mask
                id="mask7_2_1548"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="161"
                y="245"
                width="25"
                height="25"
              >
                <path d="M171.004 245.667C172.274 245.667 174.59 245.667 175.855 245.667C181.342 245.667 185.788 251.065 185.788 257.728C185.788 264.391 181.342 269.789 175.855 269.789H171.004C165.517 269.789 161.072 264.391 161.072 257.728C161.072 251.065 165.517 245.667 171.004 245.667Z" fill="white" />
              </mask>
              <g mask="url(#mask7_2_1548)">
                <path d="M173.494 246.866C178.405 240.583 179.108 232.927 175.065 229.767C171.021 226.607 163.762 229.139 158.851 235.423C153.94 241.706 153.237 249.362 157.28 252.522C161.324 255.682 168.583 253.15 173.494 246.866Z" fill="white" />
              </g>
              <path d="M175.861 269.789C181.346 269.789 185.793 264.389 185.793 257.728C185.793 251.067 181.346 245.667 175.861 245.667C170.375 245.667 165.928 251.067 165.928 257.728C165.928 264.389 170.375 269.789 175.861 269.789Z" fill="white" />
              <path d="M172.404 256.292C173.612 256.292 174.59 255.103 174.59 253.637C174.59 252.171 173.612 250.982 172.404 250.982C171.197 250.982 170.218 252.171 170.218 253.637C170.218 255.103 171.197 256.292 172.404 256.292Z" fill="#20F3E0" />
              <path d="M179.312 256.292C180.519 256.292 181.498 255.103 181.498 253.637C181.498 252.171 180.519 250.982 179.312 250.982C178.104 250.982 177.125 252.171 177.125 253.637C177.125 255.103 178.104 256.292 179.312 256.292Z" fill="#20F3E0" />
              <path d="M172.404 264.48C173.612 264.48 174.59 263.292 174.59 261.825C174.59 260.359 173.612 259.171 172.404 259.171C171.197 259.171 170.218 260.359 170.218 261.825C170.218 263.292 171.197 264.48 172.404 264.48Z" fill="#20F3E0" />
              <path d="M179.312 264.48C180.519 264.48 181.498 263.292 181.498 261.825C181.498 260.359 180.519 259.171 179.312 259.171C178.104 259.171 177.125 260.359 177.125 261.825C177.125 263.292 178.104 264.48 179.312 264.48Z" fill="#20F3E0" />
              <path d="M172.404 256.292C173.612 256.292 174.59 255.103 174.59 253.637C174.59 252.171 173.612 250.982 172.404 250.982C171.197 250.982 170.218 252.171 170.218 253.637C170.218 255.103 171.197 256.292 172.404 256.292Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M179.312 256.292C180.519 256.292 181.498 255.103 181.498 253.637C181.498 252.171 180.519 250.982 179.312 250.982C178.104 250.982 177.125 252.171 177.125 253.637C177.125 255.103 178.104 256.292 179.312 256.292Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M172.404 264.48C173.612 264.48 174.59 263.292 174.59 261.825C174.59 260.359 173.612 259.171 172.404 259.171C171.197 259.171 170.218 260.359 170.218 261.825C170.218 263.292 171.197 264.48 172.404 264.48Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M179.312 264.48C180.519 264.48 181.498 263.292 181.498 261.825C181.498 260.359 180.519 259.171 179.312 259.171C178.104 259.171 177.125 260.359 177.125 261.825C177.125 263.292 178.104 264.48 179.312 264.48Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M175.861 269.789C181.346 269.789 185.793 264.389 185.793 257.728C185.793 251.067 181.346 245.667 175.861 245.667C170.375 245.667 165.928 251.067 165.928 257.728C165.928 264.389 170.375 269.789 175.861 269.789Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M162.482 251.523C164.221 248.014 167.386 245.667 171.004 245.667C172.274 245.667 174.59 245.667 175.855 245.667C181.342 245.667 185.788 251.065 185.788 257.728C185.788 264.391 181.342 269.789 175.855 269.789H171.004C165.517 269.789 161.072 264.391 161.072 257.728C161.072 256.812 161.155 255.921 161.316 255.063C161.405 254.573 161.525 254.094 161.66 253.631" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
              <path d="M161.946 255.714L161.332 255.026H156.485L157.1 255.719C159.26 258.155 161.02 260.909 162.441 263.839C164.169 267.4 167.355 269.795 171.004 269.795H175.855C172.206 269.795 169.02 267.41 167.297 263.85C165.871 260.914 164.112 258.155 161.946 255.719V255.714Z" fill="#00A0FF" />
              <path d="M161.946 255.714L161.332 255.026H156.485L157.1 255.719C159.26 258.155 161.02 260.909 162.441 263.839C164.169 267.4 167.355 269.795 171.004 269.795H175.855C172.206 269.795 169.02 267.41 167.297 263.85C165.871 260.914 164.112 258.155 161.946 255.719V255.714Z" stroke="#080966" stroke-width="1.24151" stroke-linejoin="round" />
            </g>
            <defs>
              <filter
                id="filter0_d_2_1548"
                x="0.392311"
                y="0.379272"
                width="375.215"
                height="301.177"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="0.986962" />
                <feGaussianBlur stdDeviation="0.493481" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_1548" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_1548" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.handle-error-btn {
  height: 40px;
  padding: 8px 16px 8px 16px;
  margin-top: 2rem;
  gap: 8px;
  border-radius: 8px;
  background: #1570EF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: white;
}
.error-content {
  text-align: left;
  margin-top: 5rem;
  display: flex;
  gap: 10rem;
  align-items: center;
  justify-content: center;
}
.error-action-text {
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  text-align: left;
  color: #1570EF;
}
.error-title {
  max-width: 80%;
  font-size: 48px;
  font-weight: 600;
  color: #5E5E5E;
  text-align: center;
  margin: 10% auto 0;
  line-height: 1.3;
}

.min-h-screen {
  min-height: 100vh
}

.flex {
  display: flex
}
.flex-col {
  flex-direction: column
}

.bg-white {
  --un-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--un-bg-opacity))
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.pt-14 {
  padding-top: 3.5rem
}

.text-black {
  --un-text-opacity: 1;
  color: rgb(0 0 0 / var(--un-text-opacity))
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-black {
    --un-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--un-bg-opacity))
  }

  .dark\:text-white {
    --un-text-opacity: 1;
    color: rgb(255 255 255 / var(--un-text-opacity))
  }
}

@media (max-width: 640px) {
  .error-content {
    flex-direction: column;
    text-align: center;
    gap: 3rem;
    margin-top: 3rem;
  }
  .error-title {
    margin-top: 1rem;
    font-size: 36px;
  }
  .error-action-text {
    font-size: 32px;
  }
}
</style>
