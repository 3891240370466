import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { OrganizationShort } from '~/models-ui';

const query = gql`
    query getOrganizationLogo($id: ID!) {
        getOrganization(id: $id) {
            code
            legalName
            organizationType
            logoImageUrl
        }
    }`

export const getOrganizationLogo = (data: any) : ComputedRef<OrganizationShort> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getOrganization ?? {});
}
const queryOrg = gql`
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      code
      legalName
      logoImageUrl
      vaultRefId
    }
  }`

export const getOrganizationShort = (data: any) : ComputedRef<OrganizationShort> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryOrg, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getOrganization ?? {});
}
