import gql from 'graphql-tag';
import { provideApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { FireblocksStatus, StakingPoolData } from '~/models-ui';

const query = gql`
  query FireblocksStatus($id: ID!) {
    getFireblocksStatus(organizationId: $id) {
      assets {
        chainToken
        isActivated
      }
      isProvisioned
    }
  }
  `
export const getFireblocksStatus = (data: any) : ComputedRef<FireblocksStatus> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getFireblocksStatus ?? {});
}
const queryPool = gql`
  query pluginOrganizationStakingPool($id: ID!) {
    getPluginOrganizationStakingPool(organizationId: $id) {
      id
      default
      createdAt
    }
  }
  `
export const getStakingPool = (data: any) : ComputedRef<StakingPoolData> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryPool, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getPluginOrganizationStakingPool);
}

const mutation = gql`
  mutation provisionOrganizationVault( $id: ID! ) {
    provisionOrganizationVault(organizationId: $id)
  }`

export const provisionOrganization = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
const sendMutation = gql`
  mutation resendWidget( $id: String! ) {
    resendWidget(organizationId: $id)
  }`

export const resendMailWidget = (data: any) => provideApolloClient(getClient())(() => useMutation(sendMutation, () => ({
  variables: {
    ...data
  }
})))
const mutationCreatePool = gql`
  mutation createStakingPool( $id: String! ) {
    createStakingPool(organizationId: $id, isDefault: true )
  }`

export const createDefStakingPool = (data: any) => provideApolloClient(getClient())(() => useMutation(mutationCreatePool, () => ({
  variables: {
    ...data
  }
})))
