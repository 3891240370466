import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';

// const mutationsFull = gql`
//     mutation updateUser ($id: ID!, $email: String!, $city: String, $address: String, $countryCode: String, $phoneNumber: String, $lastName: String, $firstName: String, $subdivisionCode: String, $zip: String) {
//         updateUser(input:
//         {
//             id: $id,
//             address: $address,
//             city: $city,
//             email: $email,
//             countryCode: $countryCode,
//             lastName: $lastName,
//             firstName: $firstName,
//             phoneNumber: $phoneNumber,
//             subdivisionCode: $subdivisionCode,
//             zip: $zip
//         }){
//             id
//         }
//     }
// `;
const mutations = gql`
    mutation updateUser ($id: ID!, $city: String, $address: String, $countryCode: String, $subdivisionCode: String, $zip: String) {
        updateUser(input:
        {
            id: $id,
            address: $address,
            city: $city,
            countryCode: $countryCode,
            subdivisionCode: $subdivisionCode,
            zip: $zip
        }){
            id
        }
    }
`;
const mutationsSmall = gql`
    mutation updateUser ($id: ID!, $phoneNumber: String, $lastName: String, $firstName: String) {
        updateUser(input:
        {
            id: $id,
            lastName: $lastName,
            firstName: $firstName,
            phoneNumber: $phoneNumber,
        }){
            id
        }
    }
`;
export const UpdateUser = (data: any) => provideApolloClient(getClient())(() => useMutation(data.address ? mutations : mutationsSmall, () => ({
  variables: {
    ...data
  }
})))
