import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { SearchResponse, ContributorProjection } from '~/models-ui';

const query = gql`
    query searchContributors($limit: Int, $from: Int, $id: ID) {
        searchContributors(limit: $limit, from: $from, filter: {organizationId: {eq: $id}}) {
            total
            items {
                id
                email
                name
                phoneNumber
                createdAt
                subdivisionCode
                subdivision {
                  code
                  countryCode
                  name
                }
                zip
                city
                address
                countryCode
                country {
                  code
                  name
                }
            }
        }
    }`
export const ContributorsLoading: Ref<boolean> = ref(false)
export const searchContributors = (data: any): ComputedRef<SearchResponse<ContributorProjection>> => {
  ContributorsLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  ContributorsLoading.value = loading
  return computed(() => result.value?.searchContributors ?? {});
}
