import logoEth from 'assets/images/eth.png';
import logosEth from 'assets/images/stEth.png';
/* eslint-disable no-unused-vars */
export interface Token {
  name: TokenName;
  chainTokenId: string;
  icon: string;
}
export enum AccountSetup {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed'
}

export enum ApprovalStatus {
  InReview = 'InReview',
  Approved = 'Approved',
  Denied = 'Denied'
}
export interface UnstakeEvent {
  hash: string,
  amount: number,
  chainTokenId: string,
  method: string
}
export interface QueryParamsTransaction {
  limit: number;
  from: number;
  id?: string;
  sort?: Array<Object>;
  filter?: {
    verified?: Object;
    organizationId?: Object;
    transactionType?: Object;
    createdAt?: Object;
    or?: Array<Object>;
    interval?: Object;
    isActive?: Object;
  };
}

export const enum FinanceTabAdmin {
  transactions = 'transactions',
  donations = 'donations',
  stripe = 'stripe'
}

export interface Tab {
    name: string;
    value: DonateTab | CampaignTab | FinanceTabAdmin;
}

export const enum TokenName {
    eth= 'ETH',
    stEth= 'stETH',
}

export const enum ClientSettingSteps {
  organizationDetails = 'details',
  finance = 'finance',
  users = 'users',
  settings = 'settings'
}

export const enum MethodWithDraw {
    instant = 1,
    standard,
}

export const enum DonateTab {
    staking= 'staking',
    oneTime = 'one-time',
    recurring = 'recurring',
    contributors = 'contributors',
}

export const enum CampaignTab {
  donor = 'transactions',
  payouts = 'payouts'
}

export const enum UnStakeStep {
    AmountSelection,
    WithDrawType,
    InProgress,
    Confirmation
}

export const TokensList: Array<Token> = [{
  name: TokenName.eth,
  chainTokenId: 'ETH',
  icon: logoEth
}, {
  name: TokenName.stEth,
  chainTokenId: 'STETH_ETH',
  icon: logosEth
}]
export const Tabs: Array<Tab> = [{
  name: 'Staking',
  value: DonateTab.staking
}, {
  name: 'One-Time',
  value: DonateTab.oneTime
}/* , {
  name: 'Recurring',
  value: DonateTab.recurring
} */] // Temporarily removed Recurring tab
export const CampaignTabs: Array<Tab> = [{
  name: 'Donor Transactions',
  value: CampaignTab.donor
}, {
  name: 'Payouts',
  value: CampaignTab.payouts
}]
export const OrgAdminTabs: Array<Tab> = [...Tabs]
OrgAdminTabs.push({
  name: 'Contributors',
  value: DonateTab.contributors
})

export const RecurringIntervals: { [key: string]: string } = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Yearly'
}

export const RecurringStatus: { [key: string]: string } = {
  true: 'Active',
  false: 'Inactive'
};
