import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { Organization } from '~/models-ui';

const query = gql`
    query Organizations($limit: Int, $from: Int, $filter: SearchableOrganizationFilterInput) {
      searchOrganizations(limit: $limit, from: $from, filter: $filter) {
        total
            items {
              id
              accountSetup
              approvalStatus
              organizationType
              totalDonations
              legalName
              setupProgress
            }
        }
    }`

export const getOrganizations = (data: any) : ComputedRef<Array<Organization>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchOrganizations ?? {});
}
