import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getDataProvider } from '~/services';
import type { Organization } from '~/models-ui';

const query = gql`
  query organizationsByCode($code: String!) {
    organizationsByCode(code: $code) {
      items {
        id
        legalName
        doingBusinessAs
        description
        logoImageUrl
        enableCardPayments
        enableCryptoPayments
        enableCryptoStaking
        socialAccounts {
          type
          url
        }
        website
      }
    }
  }`

export const getPublicOrganization = (data: any) : ComputedRef<Organization> => {
  const runQuery = (data: any): any => provideApolloClient(getDataProvider())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => {
    const items = result.value?.organizationsByCode?.items;
    return items && items.length > 0 ? items[0] ?? {} : {};
  });
}

const queryPoll = gql`
  query OrganizationStakingPool ( $id: ID! ) {
    getPluginOrganizationStakingPool(organizationId: $id){
      id
    }
  }`
export const getDefStakingPool = (data: any) : ComputedRef<Organization> => {
  const runQuery = (data: any): any => provideApolloClient(getDataProvider())(() => useQuery(queryPoll, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getPluginOrganizationStakingPool ?? {});
}
