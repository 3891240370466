import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { SearchResponse, SearchRecurringItem } from '~/models-ui';

const querySearchRecurring = gql`
  query searchRecurring($limit: Int, $from: Int, $filter: SearchableSubscriptionContributorFilterInput, $sort: [SearchableSubscriptionContributorSortInput] ) {
    searchSubscriptionContributors(limit: $limit, from: $from, filter: $filter, sort: $sort) {
      nextToken
      total
      items {
        id
        createdAt
        donationType
        contributionFiatAmount
        fiatAmount
        fiatCurrency
        interval
        isActive
        user{
          firstName
          lastName
          email
        }
        userRequest{
          firstName
          lastName
          email
        }
      }
    }
  }`
export const UserRecurringLoading: Ref<boolean> = ref(false)
export const searchUserRecurringData = (data: any): ComputedRef<SearchResponse<SearchRecurringItem>> => {
  UserRecurringLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(querySearchRecurring, data))
  const { result, loading } = runQuery(data)
  UserRecurringLoading.value = loading
  return computed(() => result.value?.searchSubscriptionContributors ?? {});
}
