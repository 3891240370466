import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { SearchUsers } from '~/models-ui';

const query = gql`
    query Users($limit: Int, $from: Int, $id: ID) {
      searchUsers(limit: $limit, from: $from, filter: {organizationId: {eq: $id}}) {
        total
        items {
          id
          address
          city
          email
          firstName
          lastName
          status
          organizationId
        }
      }
    }`

export const getUsers = (data: any) : ComputedRef<SearchUsers> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUsers ?? {});
}
