import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { OrganizationTransaction } from '~/models-ui';

const query = gql`

    query OrganizationTransactions($id: String!, $from: String, $to: String) {
        searchOrganizationTransactions(organizationId: $id, fromDate: $from, toDate: $to) {
            updatedAt
            createdAt
            chainTokenId
            amount
            address
            fiatAmount
            accountNumberLast4
            fiatCurrency
            error
            id
            status
            transactionType
        }
    }`
export const OrganizationTransactionsLoading: Ref<boolean> = ref(false)
export const getOrganizationTransactions = (data: any): ComputedRef<Array<OrganizationTransaction>> => {
  OrganizationTransactionsLoading.value = true;
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  OrganizationTransactionsLoading.value = loading
  return computed(() => result.value?.searchOrganizationTransactions
    // eslint-disable-next-line no-unsafe-optional-chaining
    ? [...result.value?.searchOrganizationTransactions].sort(function (a: OrganizationTransaction, b: OrganizationTransaction) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      })
    : [])
}
