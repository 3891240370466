export interface UserAttributes {
  email: string,
  [key: string]: boolean | number | string;
}
// given_name?: string,
// family_name?: string,
// address?: string,
export interface UserAttr {
  userAttributes: UserAttributes,
  autoSignIn: boolean
}
export interface UserSignUpData {
  username: string,
  password: string,
  options: UserAttr
}
export enum UserRoles {
  // eslint-disable-next-line no-unused-vars
  User,
  // eslint-disable-next-line no-unused-vars
  Admin,
  // eslint-disable-next-line no-unused-vars
  OrgAdmin
}
export interface MfaResponse {
  isOk: boolean,
  message?: string
}
export interface SearchUsers {
  total: number
  items: UserData[]
}

export interface UserData {
  id?: string
  address?: string
  city?: string
  email: string
  zip?: string
  countryCode?: string
  phoneNumber?: string
  subdivisionCode?: string | null
  firstName?: string
  lastName?: string
  status?: string
  organizationId?: string
}
