import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';

const mutation = gql`
    mutation UpdateOrganization($id: ID!, $state: OrganizationApprovalStatus) {
      updateOrganization(input: {
        id: $id,
        approvalStatus: $state
      }) {
        id
      }
    }`

export const Update = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
