import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';
const mutation = gql`
    mutation inviteOrganizationUser($email: String!, $id: String!, $firstName: String!, $lastName: String! ) {
      inviteOrganizationUser(
        email: $email,
        organizationId: $id,
        firstName: $firstName,
        lastName: $lastName)
    }`
const mutationReInvite = gql`
    mutation resendInviteOrganizationUser($userId: String!, $id: String!) {
      resendInviteOrganizationUser(
        organizationId: $id,
        userId: $userId)
    }`

export const inviteUser = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
export const resendInviteUser = (data: any) => provideApolloClient(getClient())(() => useMutation(mutationReInvite, () => ({
  variables: {
    ...data
  }
})))
