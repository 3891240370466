import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';

const mutation = gql`
    mutation UpdateOrganization($id: ID!, $anonymous: Boolean, $cardPayments: Boolean, $cryptoPayments: Boolean, $cryptoStaking: Boolean ) {
      updateOrganization(input: {
        id: $id,
        enableAnonymousDonations: $anonymous,
        enableCardPayments: $cardPayments,
        enableCryptoPayments: $cryptoPayments,
        enableCryptoStaking: $cryptoStaking,
      }) {
        id
      }
    }`

export const UpdateWidget = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
