import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';

export interface GetOrganizationImageUploadDataParam {
  organizationId: string;
}

const query = gql`
    query getOrganizationImageUploadData($organizationId: ID!) {
        getOrganizationImageUploadData(organizationId: $organizationId)
    }`

export const getOrganizationImageUploadData = (data: GetOrganizationImageUploadDataParam) : ComputedRef<string> => {
  const runQuery = (data: GetOrganizationImageUploadDataParam): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getOrganizationImageUploadData ?? '');
}
