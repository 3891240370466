const text = ref('')
const isTemporary = ref(true)
export const setBanner = (message: string, isTemp = true) => {
  text.value = message
  isTemporary.value = isTemp
  if (isTemporary.value) {
    setTimeout(() => { text.value = '' }, 5300)
  }
}

export const getBannerMessage = () => { return text.value }
export const isPersistent = () => { return !isTemporary.value }
