import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { BankAccount } from '~/models-ui';

const query = gql`
    query getBankAccounts($id: String!) {
      getBankAccounts(organizationId: $id) {
        accountHolder
        accountNumberLast4
        id
      }
    }`

export const getBankAccounts = (data: any) : ComputedRef<Array<BankAccount>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, error } = runQuery(data)
  return computed(() => error.value ? [] : result.value?.getBankAccounts ?? []);
}
