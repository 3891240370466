import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { SearchResponse, SearchItems, StakingTransaction, RecurringPaymentProjection } from '~/models-ui';

const bodyQuery = `
            nextToken
            total
            items {
                amount
                chainTokenId
                email
                id
                from
                to
                hash
                createdAt
                updatedAt
                donationType
                fiatAmount
                fiatCurrency
                transactionType
                feeAmount
                feeFiatAmount
                feeFiatCurrency
                netAmount
                netFiatAmount
                chainToken {
                    name
                    symbol
                }
                userRequest {
                    firstName
                    lastName
                    email
                }
                user {
                    firstName
                    email
                    lastName
                }
            }`
const query = gql`
    query searchUserTransactions($limit: Int, $from: Int, $filter: SearchableUserTransactionFilterInput, $sort: [SearchableUserTransactionSortInput] ) {
        searchUserTransactions(limit: $limit, from: $from, filter: $filter, sort: $sort) {
            ${bodyQuery}
        }
    }`
const queryUser = gql`
    query searchUserTransactions($id: ID, $email: String) {
        searchUserTransactions(filter: { organizationId: {eq: $id}, email: {eq: $email}, verified: {eq: true}, transactionType: {ne: "StakingWithdrawal"}}, sort: {direction: desc, field: createdAt}) {
            ${bodyQuery}
        }
    }`
const queryPayouts = gql`
    query searchUserTransactions($id: ID, $stakingPoolId: ID) {
        searchUserTransactions(filter: { organizationId: {eq: $id}, transactionType: {eq: "StakingPayout"}, stakingPoolId: {eq: $stakingPoolId}}, sort: {direction: desc, field: createdAt}) {
            total
            items {
                amount
                fiatAmount
                createdAt
                transactionType
                chainToken {
                    symbol
                }
            }
        }
    }`
const queryUserTransactionsByStakingPool = gql`
  query searchUserTransactions($limit: Int, $from: Int, $id: ID, $stakingPoolId: ID) {
    searchUserTransactions(limit: $limit, from: $from,filter: { organizationId: {eq: $id}, transactionType: {ne: "StakingPayout"}, stakingPoolId: {eq: $stakingPoolId}}, sort: {direction: desc, field: createdAt}) {
      total
      items {
        id
        email
        createdAt
        updatedAt
        amount
        donationType
        from
        to
        transactionType
        userRequestId
        userRequest {
          firstName
          lastName
        }
        user {
          firstName
          lastName
        }
      }
    }
  }`

const queryUserTransactionsBySubscription = gql`
  query searchUserTransactions($subscriptionId: ID) {
    searchUserTransactions(filter: { subscriptionId: {eq: $subscriptionId} }, sort: {direction: desc, field: createdAt}) {
      items {
        id
        createdAt
        fiatAmount
        fiatCurrency
        netFiatAmount
        feeFiatAmount
        donationType
      }
    }
  }`
export const UserTransactionsLoading: Ref<boolean> = ref(false)
export const searchUserTransactionsData = (data: any): ComputedRef<SearchResponse<SearchItems>> => {
  UserTransactionsLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  UserTransactionsLoading.value = loading
  return computed(() => result.value?.searchUserTransactions ?? {});
}
export const searchUserTransactionsByUser = (data: any): ComputedRef<SearchResponse<SearchItems>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryUser, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUserTransactions ?? {});
}
export const organizationPayouts = (data: any): ComputedRef<SearchResponse<SearchItems>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryPayouts, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUserTransactions.items ?? {});
}
export const getUserTransactionsByStakingPoolId = (data: any): ComputedRef<SearchResponse<StakingTransaction>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryUserTransactionsByStakingPool, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUserTransactions ?? {});
}

export const getUserTransactionsBySubscription = (data: any): ComputedRef<SearchResponse<RecurringPaymentProjection>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryUserTransactionsBySubscription, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUserTransactions ?? {});
}
