import { fetchAuthSession, getCurrentUser, signOut, setUpTOTP, verifyTOTPSetup, updateMFAPreference } from '@aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { type MfaResponse, type OrganizationShort, OrganizationType, UserRoles } from '~/models-ui';
import { getOrganizationLogo, onUpdateOrganization } from '~/customQueries/organization';

const unAuthorizedUrls = ['/', '/signin', '/signup', '/forgot-password', '/connect', '/user-signup', '/business-signup', '/payments/:code()']
interface PartRoute {
  path: string;
  fullPath: string;
}
export const currentUser = ref('')
export const currentUserId = ref('')
export const userWallet: Ref<string | undefined> = ref(undefined)
export const role = ref(UserRoles.User)
export const organization: OrganizationShort = reactive({ id: '' } as OrganizationShort)
export const authToken = ref('')

const client = generateClient()
let organizationUpdateSubscription: any = null
function updateOrganization (id: string) {
  const org = getOrganizationLogo({ id })
  watch(org, (val: OrganizationShort) => {
    organization.legalName = val.legalName;
    organization.code = val.code;
    organization.logoImageUrl = val.logoImageUrl;
    organization.organizationType = val.organizationType;
  })
}

export const currentAuthenticatedUser = async function (fallbackUrl: string | PartRoute, loading: Ref<boolean>) {
  let newUrl = getHomeUrl();
  if (currentUser.value && newUrl) {
    await navigateTo(newUrl)
    loading.value = false
  } else {
    try {
      const { username, userId } = await getCurrentUser();
      currentUser.value = username;
      currentUserId.value = userId;
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      authToken.value = idToken?.toString() || '';
      userWallet.value = idToken?.payload['custom:wallet_address'] as string
      if (!isWalletUser()) {
        const orgId = idToken?.payload['custom:organization_id']
        if (accessToken?.payload['cognito:groups'] && (accessToken?.payload['cognito:groups'] as Array<string>).includes('Admin')) {
          role.value = UserRoles.Admin
        } else if (orgId) {
          role.value = UserRoles.OrgAdmin
          organization.id = orgId as string
          updateOrganization(organization.id)

          const variables = {
            id: organization.id
          };
          if (organizationUpdateSubscription) {
            organizationUpdateSubscription.unsubscribe()
          }
          organizationUpdateSubscription = (client
            .graphql({ query: onUpdateOrganization, variables }) as any)
            .subscribe({
              next: () => updateOrganization(organization.id),
              error: (error: any) => console.warn(error)
            });
        }
        newUrl = getHomeUrl();
      }
      if (newUrl) {
        await navigateTo(newUrl)
      }
    } catch (err) {
      await navigateTo((typeof fallbackUrl === 'string')
        ? unAuthorizedUrls.includes(fallbackUrl) ? fallbackUrl : '/'
        : unAuthorizedUrls.includes(fallbackUrl.path) || unAuthorizedUrls.includes((fallbackUrl as any).matched[0]?.path) ? fallbackUrl.fullPath : '/');
    } finally {
      loading.value = false
    }
  }
}
export const getHomeUrl = () => {
  return unAuthorizedUrls.includes(location.pathname)
    ? (role.value === UserRoles.Admin ? '/clients' : (role.value === UserRoles.OrgAdmin ? '/home' : '/donations'))
    : null;
}
export const isAdmin = () => {
  return role.value === UserRoles.Admin
}
export const isIndividual = () => {
  return organization && organization.organizationType === OrganizationType.Individual
}
export const organizationId = () => {
  return organization.id
}
export const organizationCode = () => {
  return organization.code
}
export const isWalletUser = () => {
  return userWallet.value
}
export const handleSignOut = async (fallbackUrl = '/') => {
  try {
    await signOut();
    currentUser.value = '';
    userWallet.value = undefined;
    authToken.value = '';
    organization.id = '';
    organization.code = '';
    organization.legalName = '';
    organization.logoImageUrl = '';
    role.value = UserRoles.User;
    if (organizationUpdateSubscription) {
      organizationUpdateSubscription.unsubscribe();
      organizationUpdateSubscription = null;
    }
    await navigateTo(fallbackUrl)
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
export const getAuthenticatorSetupUrl = async () => {
  try {
    const setupDetails = await setUpTOTP();
    const appName = 'Crowdstake';
    return setupDetails.getSetupUri(appName);
  } catch (error) {
    console.log('Error generating Authenticator setup url: ', error);
    return undefined;
  }
}
export const verifyOneTimeCode = async (code: string): Promise<MfaResponse> => {
  try {
    await verifyTOTPSetup({ code });
    return { isOk: true };
  } catch (error: any) {
    return { isOk: false, message: error.message };
  }
}

export const confirmAuthenticatorSetup = async () => {
  await updateMFAPreference({ totp: 'PREFERRED' });
}
