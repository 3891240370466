import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { OrganizationForAdmin } from '~/models-ui';

const query = gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            address
            city
            code
            ein
            countryCode
            industryCode
            doingBusinessAs
            organizationType
            receiveDonationNotification
            legalName
            logoImageUrl
            phoneNumber
            description
            otherUrl
            socialAccounts {
                type
                url
            }
            contacts {
                firstName
                lastName
                title
                canSignTaxReceipts
            }
            website
            widgetUrl
            zip
            setupProgress
            stripeCapabilityState
            subdivisionCode
            id
        }
    }`
const querySupeAdmin = gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            address
            city
            code
            ein
            fee
            countryCode
            industryCode
            doingBusinessAs
            organizationType
            receiveDonationNotification
            legalName
            logoImageUrl
            phoneNumber
            description
            otherUrl
            socialAccounts {
                type
                url
            }
            contacts {
                firstName
                lastName
                title
                canSignTaxReceipts
            }
            website
            widgetUrl
            zip
            setupProgress
            stripeCapabilityState
            subdivisionCode
            id
        }
    }`

export const dataOrganizationLoading = ref(false)
export const getOrganizationDataForAdmin = (data: any) : ComputedRef<OrganizationForAdmin> => {
  dataOrganizationLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  dataOrganizationLoading.value = loading
  return computed(() => result.value?.getOrganization ?? {});
}
export const getOrganizationDataForSuperAdmin = (data: any) : ComputedRef<OrganizationForAdmin> => {
  dataOrganizationLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(querySupeAdmin, data))
  const { result, loading } = runQuery(data)
  dataOrganizationLoading.value = loading
  return computed(() => result.value?.getOrganization ?? {});
}
