import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { EstimateSellRequest } from '~/models-ui';

const query = gql`
    query estimateSellRequest($id: String!, $amount: Float!, $chainTokenId: String!) {
      estimateSellRequest(organizationId: $id, amount: $amount, chainTokenId: $chainTokenId, currency: "USD" ) {
        exchangeRate
        feeAmount
        feeFiatAmount
        amount
        netAmount
        netFiatAmount
        currency
      }
    }`

export const getEstimateSellRequest = (data: any) : ComputedRef<EstimateSellRequest> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.estimateSellRequest ?? {});
}
