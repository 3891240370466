import gql from 'graphql-tag';
import { provideApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { UserData } from '~/models-ui';

const query = gql`
    query getUser($id: ID) {
      searchUsers(filter: {id: {eq: $id}}) {
        items {
          id
          address
          city
          email
          phoneNumber
          zip
          countryCode
          subdivisionCode
          firstName
          lastName
          status
          organizationId
        }
      }
    }`
const queryMfa = gql`
  query getUserMFA($id: String!) {
    getUserMFA(userId: $id)
  }`
const mutationMfa = gql`
  mutation resetUserMFA($id: String!) {
    resetUserMFA(userId: $id)
  }`
const mutation = gql`
  mutation saveUser($id: String!, $firstName: String, $lastName: String, $phoneNumber: String, $address: String, $city: String,$zip: String,$subdivisionCode: String,$countryCode: String ) {
    saveUser(
      userId: $id,
      phoneNumber: $phoneNumber,
      firstName: $firstName,
      lastName: $lastName,
      address: $address,
      city: $city,
      zip: $zip,
      countryCode: $countryCode,
      subdivisionCode: $subdivisionCode)
  }`

export const getUser = (data: any) : ComputedRef<UserData> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.searchUsers.items[0] ?? {});
}
export const getUserMfa = (data: any) : ComputedRef<Array<string>> => {
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(queryMfa, data))
  const { result } = runQuery(data)
  return computed(() => result.value?.getUserMFA ?? {});
}
export const SaveUser = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))

export const ResetMfaUser = (data: any) => provideApolloClient(getClient())(() => useMutation(mutationMfa, () => ({
  variables: {
    ...data
  }
})))
