import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { Organization } from '~/models-ui';

const query = gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            id
            stripeAccountId
            stripeCapabilityState
        }
    }`

export const dataLoading = ref(false)
export const getStripeAccountIdByOrgId = (data: any) : ComputedRef<Organization> => {
  dataLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  dataLoading.value = loading
  return computed(() => result.value?.getOrganization ?? {});
}
