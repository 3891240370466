import { PoolType, TypeOfTransaction } from '~/models-ui/';
export { type Organization } from '~/graphql/API';
/* eslint-disable no-unused-vars */
export enum FiatCurrency {
  USD = 'USD',
}
export enum IndustryCode {
  NonProfit = 'non-profit',
  ContentCreator = 'content-creator'
}
export enum AccountDetailSteps {
  personalDetails = 'personal-details',
  password = 'password',
  Mfa = 'mfa'
}

export interface OrganizationShort {
  id: string
  legalName?: string
  code: string
  vaultRefId?: string
  organizationType: OrganizationType
  logoImageUrl?: string
}

export interface BankAccount {
  accountHolder?: string
  accountNumberLast4: string
  id: string
}

export interface EstimateSellRequest {
  exchangeRate: number
  feeAmount: number
  feeFiatAmount: number
  amount: number
  netAmount: number
  netFiatAmount: number
  currency: string
}
export enum WithdrawalBalanceType {
  USD = 'USD',
  Crypto = 'Crypto',
}
export enum OrganizationType {
  Individual = 'Individual',
  Company = 'Company',
}
export enum WithdrawalState {
  view,
  preview,
  mfa,
  finish
}
export enum WizardSteps {
  Details = 'Details',
  Profile = 'Profile'
}
export enum Socials {
  twitch = 'twitch',
  youtube = 'youtube',
  x = 'x',
  facebook = 'facebook',
  instagram = 'instagram',
  linkedin = 'linkedin'
}
export interface SocialAccount {
    type: Socials
    url: string
}
export interface OrgContact {
  firstName: string
  title: string
  lastName: string
  canSignTaxReceipts: boolean
}
export interface OrganizationDetailsForAdmin {
  doingBusinessAs: string
  ein: string
  address: string
  city: string
  countryCode: string | undefined
  industryCode: string
  legalName: string
  phoneNumber: string
  organizationType: OrganizationType
  contacts: Array<OrgContact>
  zip: string
  setupProgress: Array<string>
  subdivisionCode: string | undefined
  id: string
  fee?: string
  receiveDonationNotification: boolean
}
export interface OrganizationProfileForAdmin {
  description: string
  logoImageUrl: string
  otherUrl: string
  socialAccounts?: Array<SocialAccount>
  website: string
  setupProgress: Array<string>
  id: string
}
export interface UserDetails {
  firstName: string;
  lastName: string;
}
export interface StakingPoolByOrganization {
  name: string;
  stakedAmount: number;
  type: PoolType;
  numberOfContributors: number;
  donatedApyAmount: number;
  id: string;
}
export interface StakingTransaction {
  email?: string
  createdAt: string
  updatedAt: string
  amount: number
  donationType: string
  transactionType: TypeOfTransaction
  from: string
  user?: UserDetails
  userRequest?: UserDetails
}
export interface FireblocksStatus {
  assets: Asset[]
  isProvisioned: boolean
}

export interface Asset {
  chainToken: string
  isActivated: boolean
}

export interface StakingPoolData {
  id: string
  default: boolean
  createdAt: string
}
