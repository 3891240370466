import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, type ComputedRef } from 'vue';
import { getClient } from '~/services';
import type { WalletItem } from '~/models-ui';

const query = gql`
    query WalletBalance($id: String!) {
        loadOrganizationVaultAccounts(organizationId: $id) {
            availableAmount
            feeAmount
            chainTokenId
            chainToken {
                name
                symbol
            }
        }
    }`
export const WalletBalanceLoading: Ref<boolean> = ref(false)
export const getWalletBalance = (data: any): ComputedRef<Array<WalletItem>> => {
  WalletBalanceLoading.value = true
  const runQuery = (data: any): any => provideApolloClient(getClient())(() => useQuery(query, data))
  const { result, loading } = runQuery(data)
  WalletBalanceLoading.value = loading
  return computed(() => result.value?.loadOrganizationVaultAccounts ?? []);
}
