import gql from 'graphql-tag';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { getClient } from '~/services';

const mutation = gql`
    mutation UpdateOrganizationByOrgAdmin($id: ID!, $organizationType: OrganizationType, $fee: Float, $ein: String, $address: String, $city: String, $code: String, $subdivisionCode: String, $countryCode: String, $doingBusinessAs: String, $industryCode: String, $logoImageUrl: String, $legalName: String, $zip: String, $phoneNumber: String, $otherUrl: String, $contacts: [ContactPersonInput], $website: String, $widgetUrl: String, $socialAccounts: [SocialAccountInput], $setupProgress: [String], $receiveDonationNotification: Boolean, $description: String) {
        updateOrganization(input: {
            id: $id,
            code: $code,
            address: $address,
            city: $city,
            countryCode: $countryCode,
            industryCode: $industryCode,
            logoImageUrl: $logoImageUrl,
            receiveDonationNotification: $receiveDonationNotification,
            doingBusinessAs: $doingBusinessAs,
            legalName: $legalName,
            organizationType: $organizationType,
            fee: $fee,
            ein: $ein,
            description: $description,
            subdivisionCode: $subdivisionCode,
            zip: $zip,
            phoneNumber: $phoneNumber,
            otherUrl: $otherUrl,
            website: $website,
            widgetUrl: $widgetUrl,
            contacts: $contacts,
            socialAccounts: $socialAccounts,
            setupProgress: $setupProgress,
        }) {
            id
        }
    }`

export const UpdateByAdmin = (data: any) => provideApolloClient(getClient())(() => useMutation(mutation, () => ({
  variables: {
    ...data
  }
})))
