import * as Sentry from "@sentry/vue";

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration({
    router: router,
  });
  const replayIntegration = Sentry.replayIntegration({
    maskAllText: true,
    blockAllMedia: true,
  })
  return [browserTracing, replayIntegration];
}



export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const config = useRuntimeConfig();

    Sentry.init({
      environment: config.public.VITE_ENV as string,
      app: vueApp,
      dsn: config.public.VITE_SENTRY_DSN_PUBLIC ?? null,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.public.VITE_SENTRY_TRACES_SAMPLE_RATE as number,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config.public.VITE_SENTRY_REPLAY_SAMPLE_RATE as number,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config.public.VITE_SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
      beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
          return null;
        }
        return event;
      },
    });
  }
});
