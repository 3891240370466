import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default defineNuxtPlugin({
  name: 'vue-tel-input',
  parallel: true,

  setup (nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    vueApp.use(VueTelInput as any);
  }
})
